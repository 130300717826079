.checkbox__image {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  margin-top: 0.5rem;
  /* width: 100%; */
}
.checkbox__image .checkbox__input {
  appearance: none;
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  width: 1rem;
  height: 1rem;
  appearance: none;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 5px;
}
.checkbox__image .checkbox__input:checked {
  background-color: var(--defaultPink);
  border: 1px solid var(--defaultPink);
  /* appearance: none; */
}
.checkbox__image .checkbox__text {
  font-size: 0.95rem;
  color: var(--dark);
  margin-bottom: 0 !important;
  cursor: pointer;
}
.checkbox__image .checkbox__img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.icon__container i {
  font-size: 20pt;
}
.circulo-red-social .icon__container {
  display: grid;
  place-content: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.circulo-instagram .icon__container {
  background-color: var(--defaultPink);
  color: var(--white);
}
.circulo-facebook .icon__container {
  background-color: #3b5998;
  color: var(--white);
}
.circulo-youtube .icon__container {
  background-color: #cc202d;
  color: var(--white);
}
.circulo-twitter .icon__container {
  background-color: #55acee;
  color: var(--white);
}
.circulo-tiktok .icon__container {
  background-color: #000000;
  color: var(--white);
}
.circulo-linkedin .icon__container {
  background-color: #007ab9;
  color: var(--white);
}
@media screen and (max-width: 767px) {
  .checkbox__image .checkbox__img {
    width: 35px;
    height: 35px;
  }
  .checkbox__image {
    width: 48%;
  }
}
