.quantity_box {
  width: 120px;
  height: 40px;
  background: #F6F7F8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.quantity_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #50318B;
  width: 40px;
  height: 40px;
  border: 0;
  transition: .2s all;
  background: none;
  cursor: pointer;
}
.quantity_btn:hover {
  background: #E8E8E8;
}
.quantity_number {
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #262626;
}