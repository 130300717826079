.audience__content {
  width: 100%;
  height: 20rem;
  box-shadow: 1px 1px 10px rgb(209, 204, 204);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.audience__content__title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
}
.audience__content__title h3 {
  color: var(--defaultPurple);
  font-size: 1.4rem;
}
.audience__content__title button {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
}
.statistic__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-evenly;
}
.statistic__container :nth-child(1) .statistic__bar--progress {
  background-color: var(--defaultPink);
}
.statistic__container :nth-child(3) .statistic__bar--progress {
  background-color: var(--defaultBlue);
}
