.box-form {
  width: 90%;
  max-width: 420px;
  margin: 30px auto;
  box-shadow: 0 3px 5px rgba(0,0,0,.2);
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;
}
.box-form h4 {
  text-align: center;
}
.form-row {
  margin-bottom: 15px;
}
.btn-form {
  background: #50318B !important;
  color: #ffffff !important;
}
.btn-form:hover {
  background: #3A1C72 !important;
}
.menu-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.box-form a {
  color: #DB308B;
  transition: .2s all;
}
.box-form a:hover {
  color: #2AA9DB;
  text-decoration: none;
}
.box-form input[type=checkbox] {
  margin-right: 5px;
}
.box-form.big {
  max-width: 680px;
}
.form-link {
  margin-left: 5px;
  color: #DB308B;
}
.form-link:hover  {
  color: #DB308B;
}