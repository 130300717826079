.recent__posts-contents {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
}
.recent__posts-contents .item {
  flex: 0 0 auto;
  margin-bottom: 10px;
}
.youtube_container {
  width: 350px;
}
.youtube_container a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.youtube_container i {
  color: #ff0000;
  font-size: 40px;
  position: absolute;
  z-index: 1;
}
.youtube_container img {
  width: 100%;
  height: auto;
}
.twitter_container {
  width: 300px;
  height: 100%;
  margin-bottom: 10px;
}
.twitter_header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.twitter_header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.twitter_container a {
  display: block;
  padding: 10px;
  border: 1px solid var(--bgCalendar);
  border-radius: 5px;
  color: var(--black);
  font-family: 'poppinsregular';
}
.twitter_name {
  font-family: 'poppinsbold';
}
.twitter_username {
  color: var(--gray);
}
.twitter_container a:hover {
  color: var(--black);
  text-decoration: none;
}
.twitter_footer {
  border-top: 1px solid var(--bgCalendar);
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}
.twitter_footer div {
  width: calc(100% / 4);
  font-size: 14px;
  text-align: center;
}
.twitter_footer div i {
  margin-right: 5px;
}
.facebook__content {
  width: 250px;
}
.facebook__content a {
  display: block;
  padding: 10px;
  border: 1px solid var(--bgCalendar);
  border-radius: 5px;
  margin-bottom: 10px;
  color: var(--black);
}
.facebook__content a:hover {
  color: var(--black);
  text-decoration: none;
}
.facebook__image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebook__image i {
  color: var(--white);
  font-size: 40px;
  z-index: 1;
  position: absolute;
}
.facebook__image img {
  width: 228px;
  height: 228px;
  object-fit: cover;
  background-color: var(--lightGray);
}
.facebook__image-title {
  width: 100%;
  padding: 5px 10px;
  background-color: var(--modal);
  color: var(--white);
  position: absolute;
  left: 0;
  bottom: 0;
}
.facebook__content-text {
  padding: 10px 0;
  font-size: 16px;
}
.facebook__share {
  width: 100%;
  height: 40px;
  background-color: #000000;
  /* position: absolute;
  left: 0;
  bottom: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebook__icon {
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebook__icon,
.facebook__icon i {
  font-size: 14px;
  color: var(--white);
}
.facebook__icon i {
  position: initial;
  margin-right: 3px;
}
.tiktok__content {
  position: relative;
}
.tiktok__content img {
  width: 200px;
}
.tiktok__content a {
  color: var(--white);
}
.tiktok__content a:hover {
  text-decoration: none;
  color: var(--white);
}
.tiktok__footer {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.tiktok__icon {
  width: calc(100% / 4);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.tiktok__icon i {
  margin-right: 3px;
}
