.dashboardBalance_content {
  width: 100%;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #db308b;
  color: #ffffff;
  text-align: center;
}
.dashboardBalance_title {
  text-align: center;
  margin-bottom: 10px;
}
.dashboardBalance_title-balance {
  font-size: 15px;
}
.dashboardBalance_number {
  font-size: 18px;
}
.dashboardBalance_box-progress-bar {
  width: 80%;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.dashboardBalance_progress-bar {
  height: 100%;
  background: #ffffff;
}
