.influencer__options {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.influencer__options button {
  border: none;
  background-color: transparent;
  color: var(--defaultPurple);
  font-weight: bold;
}
.influencer__options button.active {
  color: var(--defaultPink);
}
.influencer__content {
  margin: auto;
  margin-top: 1rem;
}
.total__influencers {
  visibility: hidden;
  justify-self: flex-end;
  background-color: var(--lightPurpleHover);
  border-radius: 5px;
  color: black;
  border: none;
  padding: 0.1rem 0.5rem;
  margin-left: auto;
  max-width: 2.5rem;
}

.influencer__options_btns {
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .influencer__options {
    gap: 0.2rem;
    font-size: 0.8rem;
    flex-wrap: wrap;
  }
  .influencer__content .influencer__card {
    box-shadow: 1px 1px 10px rgb(243, 235, 235);
    max-width: 100%;
  }
}
