.SelectSearch_container {
  width: 100%;
  height: 40px;
  position: relative;
}
.SelectSearch_container.line {
  border-bottom: 2px solid var(--lightPurple);
  margin-bottom: 20px;
  background-color: var(--white);
}
.SelectSearch_container.input {
  border: 1px solid rgb(228, 223, 223);
  padding: 0 10px;
  border-radius: 10px;
  background-color: var(--white);
}
.SelectSearch_container i {
  position: absolute;
  top: 40%;
  right: 5px;
  font-size: 14px;
}
.SelectSearch_container input {
  width: calc(100% - 10px);
  height: 100%;
  border: 0;
  background-color: transparent;
}
.SelectSearch_container input:focus {
  outline: none;
}
.SelectSearch_loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: var(--modalWhite);
}
.SelectSearch_loading img {
  width: 35px;
  height: 35px;
}
.SelectSearch_options {
  width: 100%;
  max-height: 150px;
  background-color: var(--white);
  position: absolute;
  top: 40px;
  overflow: auto;
  z-index: 1;
  border-radius: 5px;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: 0.2s all;
}
.SelectSearch_options button {
  width: 100%;
  padding: 7px 0;
  background-color: transparent;
  color: var(--black);
  border: 0;
  border-top: 1px solid var(--purple);
  transition: 0.2s all;
}
.SelectSearch_options button:hover {
  background-color: var(--purple);
  color: var(--white);
}
.SelectSearch_options button:first-child {
  border-top: 0;
}
.SelectSearch_container input:focus + .SelectSearch_options {
  opacity: 1;
  visibility: visible;
}
.SelectSearch_options button small {
  padding-left: 5px;
}
.SelectSearch_selected {
  width: 100%;
  max-height: 130px;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.SelectSearch_selected-item {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--purple);
  color: var(--white);
  border-radius: 10px;
  font-size: 14px;
  margin: 0 5px 5px;
}
.SelectSearch_selected-item small {
  padding-left: 3px;
}
.SelectSearch_selected-item button {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  color: var(--white);
  border: 0;
  font-size: 20px;
  background-color: transparent;
  transition: 0.2s all;
}
.SelectSearch_selected-item button:hover {
  color: var(--pink);
}
