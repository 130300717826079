.input__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}
.input__group label {
  color: var(--defaultPink);
  font-size: 1rem;
  font-weight: bold;
}
.input__group input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input__group input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.input__group input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.porposal__body .breif .input__group input:disabled {
  height: 3rem;
}

.input__number {
  display: flex;
  width: 100%;
}
.input__number span {
  background-color: rgba(192, 192, 192, 0.726);
  padding: 0.2rem 0.8rem;
  border-radius: 5px 0 0 5px;
  text-align: center;
  font-size: 1.1rem;
}
.input__number input {
  border: none;
  outline: none;
  padding: 0 0.5rem;
  border-radius: 0 5px 5px 0;
  height: 2rem;
  border: 0.5px solid rgb(172, 172, 172);
  width: 100%;
}
.input__group input:disabled {
  background-color: rgba(236, 236, 236, 0.694);
  color: gray;
}
