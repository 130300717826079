.breif {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.breif__title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--defaultPink);
}
.breif .logo__container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.breif .brand__logo {
  max-width: 250px;
  max-height: 150px;
}
/* .breif .brand__data {
  display: flex;
  justify-content: space-between;
} */
.breif .data__field {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
/* .porposal__subtitle {
  font-size: 1rem;
  color: var(--defaultPurple);
  font-weight: 600;
  margin-bottom: 0;
} */
.data__text {
  font-size: 0.9rem;
}
/* .detail__purpose {
  margin-bottom: 1rem;
} */
.two__details {
  display: flex;
  gap: 5rem;
}
.breif .porposal__text {
  line-height: 2rem;
}
.breif .hashtag__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.breif .hashtag {
  padding: 0.3rem 1rem;
  background-color: #fcd8eb;
  color: var(--defaultPink);
  border-radius: 8px;
}
.breif .keywords__campaign {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.inputdate__container {
  display: flex;
  gap: 1rem;
}
.input__date {
  flex: 1;
}
.breif .input__group {
  margin: 0;
}
.breif #campaign__deliverable {
  height: 10rem;
  padding: 1rem;
  max-height: 14.3rem;
}
.porposal__block {
  border: 0.5px solid rgb(172, 172, 172);
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  align-items: flex-start;
}
.porposal__block .checkbox__image {
  gap: 1.5rem;
}
.networks {
  display: flex;
  flex-wrap: wrap;
  /* gap: 0.4rem; */
  justify-content: space-between;
}
.block__title {
  text-align: center;
  font-size: 0.9rem;
  color: var(--defaultPink);
  font-weight: bold;
}
.breif .upload__container {
  height: 18rem;
}
.breif .uploadImage .drag-drop {
  height: 100%;
}
.interest__buttons button.disabled {
  background-color: rgba(160, 160, 160, 0.685) !important;
}
@media only screen and (max-width: 600px) {
  .two__details {
    flex-wrap: wrap;
    gap: 1rem;
  }
  .breif .data__field {
    gap: 0;
  }
}
