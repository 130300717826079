.calendar_container {
  width: 100%;
  max-width: 400px;
  position: relative;
}
.calendar_header {
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--defaultPurple);
  text-align: center;
  color: #ffffff;
}
.calendar_header button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #ffffff;
}
.calendar_title {
  font-size: 26px;
  line-height: 26px;
}
.calendar_year {
  font-size: 14px;
  margin-bottom: 5px;
}
.calendar_current-date {
  font-size: 16px;
  cursor: pointer;
}
.calendar_days {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--bgCalendar);
}
.calendar_days div,
.calendar_days button {
  width: calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  border: 0;
  background-color: transparent;
}
.calendar_days button {
  transition: 0.2s all;
  border-radius: 0;
}
.calendar_days button:hover {
  box-shadow: 0 0 8px rgba(80, 49, 139, 0.5);
}
.calendar_day.current {
  background-color: var(--mediumPurple);
  color: var(--white);
}
.calendar_day.active {
  background-color: var(--defaultPurple);
  color: var(--white);
}
.calendar_prev-day,
.calendar_next-day {
  opacity: 0.65;
}
.calendar_loading {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}
.calendar_loading.visible {
  opacity: 1;
  visibility: visible;
}
