.saleItem_container {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: row;
	border-bottom: 1px solid #646464;
	font-size: 13px;
	transition: .2s all;
}
.saleItem_content {
	width: 100%;
	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: row;
}
.saleItem_contentAll {
	width: 100%;
}
.saleItem_contentSmall {
	width: 80%;
}
.saleItem_content:hover {
	text-decoration: none;
	color: #000000;
}
.saleItem_container:hover {
	border-bottom: 1px solid #28CAE4;
}
.saleItem_width5 {
	width: 25%;
	padding: 0 5px;
}
.saleItem_width4 {
	width: 24%;
	padding: 0 5px;
}
.saleItem_box-status, .saleItem_product {
	display: flex;
	align-items: center;
}
.saleItem_status {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 5px;
}
.saleItem_status2 {
	background: #2AA9DB;
}
.saleItem_status1 {
	background: #DB308B;
}
.saleItem_status3 {
	background: red;
}
.saleItem_status4 {
	background: #50318B;
}
.saleItem_status5 {
	background: #000000;
}
.saleItem_image {
	width: 40px;
	height: 40px;
	margin-right: 5px;
}
.saleItem_action-content button, .saleItem_action-content a {
	border: 0;
	padding: 0;
	background: transparent;
	color: #212529;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 5px 5px 5px 0;
	transition: .2s all;
	text-align: left;
}
.saleItem_action-content button:hover, .saleItem_action-content a:hover {
	opacity: .75;
	text-decoration: none;
}
.saleItem_action-content button span, .saleItem_action-content a span {
	font-size: 20px;
	margin-right: 4px;
}