.paymentMethodView_header {
  margin: 0 0 20px;
}
.paymentMethodView_balance {
  background: #2aa9db;
  color: #ffffff;
  padding: 15px;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 15px 0;
  flex-wrap: wrap;
}
.paymentMethodView_balance span {
  font-size: 18px;
  margin-left: 10px;
}
.paymentMethodView_add-balance {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 20px;
  color: #ffffff;
  border: 0;
  background: #db308b;
}
.paymentMethodView_description {
  color: #50318b;
  text-align: justify;
  padding: 0 20px;
  margin-bottom: 20px;
}
.paymentMethodView_footer {
  height: 80px;
}
.paymentMethodView_img {
  width: 120px;
  height: auto;
}
.paymentMethodView_footer-text {
  display: flex;
  font-size: 12px;
  flex-direction: column;
}
.paymentMethodView_footer-text img {
  margin-top: 5px;
}
.paymentMethodView_select {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.paymentMethodView_select label {
  margin-right: 30px;
  font-family: 'poppinsregular';
  font-size: 16px;
}
.paymentMethodView_select label input {
  margin-right: 5px;
}
.paymentMethodView_select .btn-pymex {
  margin-bottom: 0;
}
