.itemProductInline_container {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.2s all;
  margin-bottom: 20px;
}
.itemProductInline_container:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.itemProductInline_container a {
  display: flex;
  align-items: center;
  color: #262626;
  transition: 0.2s all;
}
.itemProductInline_container a:hover {
  color: #50318b;
  text-decoration: none;
}
.itemProductInline_image {
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 10px;
}
.itemProductInline_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.itemProductInline_menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  visibility: hidden;
  opacity: 0;
}
.itemProductInline_image:hover .itemProductInline_menu {
  visibility: visible;
  opacity: 1;
}
.itemProductInline_menu button {
  width: 40px;
  height: 40px;
  border: 1px solid #cdc4db;
  border-radius: 50%;
  margin: 5px;
  background: transparent;
  color: #50318b;
  transition: 0.2s all;
}
.itemProductInline_menu button:hover {
  background: #50318b;
  color: #ffffff;
}
.itemProductInline_menu button:focus {
  outline: none;
}
.itemProductInline_box-info {
  width: calc(100% - 110px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.itemProductInline_name {
  font-family: 'poppinssemibold';
}
.itemProductInline_box-price {
  display: flex;
  align-items: center;
  font-family: 'poppinssemibold';
}
.itemProductInline_box-price div {
  margin-right: 10px;
}
.itemProductInline_price {
  color: #ff4858;
}
.itemProductInline_discount {
  color: #c1c8ce;
  text-decoration: line-through;
}
