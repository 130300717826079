.chatMessage_content-order {
  width: 100%;
  position: relative;
}
.chatMessage_content {
  width: 100%;
  min-height: 450px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #ffffff;
  margin-bottom: 30px;
  position: relative;
}
.chatMessage_label {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #dadfe2;
  margin-bottom: 10px;
  font-family: 'poppinsmedium';
  position: relative;
}
.chatMessage_label label {
  margin-right: 10px;
  margin-bottom: 0;
}
.chatMessage_label input {
  border: 0;
  flex: 1;
}
.chatMessage_select-user {
  width: calc(100% - 40px);
  max-height: 120px;
  position: absolute;
  overflow: auto;
  margin: auto;
  top: 35px;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.chatMessage_message {
  text-align: justify;
  height: 275px;
  overflow: auto;
  margin-bottom: 10px;
}
.chatMessage_box-message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chatMessage_box-message textarea {
  width: 100%;
  height: 240px;
  max-height: 240px;
  border: 1px solid #9b9b9b;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}
.chatMessage_box-message-response textarea {
  width: 100%;
  height: 75px;
  max-height: 75px;
  border: 1px solid #9b9b9b;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}
.chatMessage_box-message textarea:focus,
.chatMessage_label input:focus,
.chatMessage_box-message-response textarea:focus {
  outline: none;
}
.chatMessage_btn {
  background: #28cae4 !important;
  color: #ffffff !important;
}
.chatMessage_menu {
  display: flex;
  justify-content: flex-end;
}
.chatMessage_sending {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
