.campaign__row {
  display: table-row;
  border-bottom: 1px solid #ccc;
}
.campaign__row td {
  padding: 1rem;
  vertical-align: middle;

  text-align: center;
}
.campaign__data__main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.campaign__data__main img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}
.campaign__row .networks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.campaign__row .networks img {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  object-fit: cover;
}
.campaign__row .campaign__status__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  justify-content: center;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  vertical-align: middle;
}

.view__detail {
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 767px) {
  .campaign__data__main {
    gap: 1rem;
  }
  .campaign__row td {
    padding: 1rem 0.4rem;
    font-size: 0.75rem;
  }
  .campaign__data__main img {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    object-fit: cover;
  }
  .campaign__row .networks {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
  }
}
