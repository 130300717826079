.itemShoppingCart_remove {
	border: 0;
	background: transparent;
	color: #FF4252;
	font-size: 20px;
}
.itemShoppingCart_remove:focus {
	outline: none;
}
.itemShoppingCart_image {
	width: 80px;
	height: 80px;
	object-fit: cover;
	margin: 10px;
}
.itemShoppingCart_name {
	max-width: 200px;
}
.itemShoppingCart_go-order {
	background: #DB308B !important;
	color: #ffffff !important;
}
.itemShoppingCart_box-price {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	color: #262626;
}
.itemShoppingCart_discount {
	text-decoration: line-through;
}