.list__porpose__container {
  display: flex;
  flex-direction: column;
}
.list__porpose__container h3 {
  text-align: center;
}
.list__porpose__container a {
  align-self: flex-end;
  margin-top: 1rem;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  color: var(--white);
  background-color: var(--defaultPink);
  border: none;
}
.list__porpose__container a:hover {
  color: var(--white);
  box-shadow: 1px 1px 10px rgba(168, 168, 168, 0.6);
  background-color: var(--defaultPurple);
  text-decoration: none;
}
