.boxWhite_container {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
}
.boxWhite_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.boxWhite_line {
  width: 40px;
  height: 3px;
  background: #50318b;
  margin: 0 5px;
}
.boxWhite_title-text {
  max-width: calc(100% - 100px);
  text-align: center;
  color: #db308b;
  font-family: 'poppinssemibold';
  font-size: 22px;
}
@media screen and (max-width: 500px) {
  .boxWhite_title-text {
    max-width: calc(100% - 65px);
    font-size: 17px;
  }
  .boxWhite_line {
    width: 20px;
  }
}
