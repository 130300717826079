.error_container {
  width: 100%;
  padding: 30px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.error_title {
  font-size: 100px;
  line-height: 100px;
  font-family: 'roboto_monobold';
}
.error_subtitle {
  font-size: 20px;
  font-family: 'poppinsregular';
  margin-bottom: 10px;
}
.error_img {
  width: 200px;
  display: block;
  margin: 0 auto 5px;
}
