.ManagerBrands_content {
  width: 100%;
}
.ManagerBrands_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightPurple);
  margin-bottom: 10px;
}
.ManagerBrands_header button {
  padding: 8px 15px;
  border-radius: 5px;
  border: 0;
  background-color: var(--defaultPurple);
  color: var(--white);
  transition: 0.2s all;
}
.ManagerBrands_header button:hover {
  background-color: var(--purpleHover);
}
.ManagerBrands_title {
  font-size: 20px;
  font-family: 'poppinsbold';
}
.ManagerBrands_body {
  width: 100%;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
}
.ManagerBrands_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--lightPurple);
}
.ManagerBrands_item {
  padding: 5px 10px;
}
.ManagerBrands_item.item__title {
  color: var(--defaultPurple);
  font-weight: bold;
}
.ManagerBrands_item img {
  width: 50px;
  height: 50px;
}
.ManagerBrands_edit {
  background-color: var(--defaultBlue);
  border: 0;
  padding: 5px 10px;
  color: var(--white);
  border-radius: 10px;
  transition: 0.2s all;
}
.ManagerBrands_edit:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
}
