.managerHeader_container {
  width: 100%;
  height: 60px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
}
.managerHeader_box-logo {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 0 20px 0 10px;
}
.managerHeader_box-logo img {
  width: 120px;
  height: auto;
}
.managerHeader_box-logo button {
  border: 0;
  background: transparent;
  font-size: 22px;
  color: #4f308b;
}
.managerHeader_box-logo button:focus {
  outline: none;
}
.managerHeader_container-menu {
  width: calc(100% - 230px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.managerHeader_box-menu {
  width: calc(100% - 200px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.managerHeader_box-menu a {
  color: #ffffff;
  padding: 0 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
}
.managerHeader_box-menu a:hover {
  color: #28cae4;
  text-decoration: none;
}
.managerHeader_box-menu span {
  font-size: 25px;
  margin-right: 10px;
}
.managerHeader_info-user {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.managerHeader_container-image {
  width: 35px;
  height: 35px;
  position: relative;
  margin-right: 10px;
}
.managerHeader_box-image-header {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.managerHeader_box-image-header img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1025px) {
  .header_container.fixed {
    display: none !important;
  }
  .managerHeader_container {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 3;
  }
}
@media screen and (max-width: 680px) {
  .managerHeader_box-logo {
    width: 150px;
  }
  .managerHeader_box-logo button {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .managerHeader_box-logo img {
    width: 80px;
  }
  .menuHeader_width-marketplace {
    width: 100% !important;
  }
  .header_container.fixed > .managerHeader_box-logo img {
    display: none;
  }
  .header_container.fixed > .managerHeader_box-logo {
    width: 60px;
  }
}
@media screen and (max-width: 330px) {
  .header_container.fixed > .managerHeader_box-logo img {
    display: block;
  }
  .header_container.fixed > .managerHeader_box-logo {
    width: 150px;
  }
  .managerHeader_container {
    position: relative;
  }
  .managerHeader_box-logo {
    position: absolute;
    z-index: 1;
  }
}
