.influencer__container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.295);
}
.profile__photo {
  margin: 1rem 2rem;
  display: flex;
  padding: 0.4rem;
  border-radius: 7px;
  position: relative;
  border: 1px solid #dfe0e4e7;
}
.profile__photo label {
  position: absolute;
  top: 10%;
  left: 90%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  padding: 0.5rem;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  place-content: center;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(93, 93, 93, 0.905);
  cursor: pointer;
  max-width: calc(100% - 32px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--defaultPink);
}
.profile__photo img {
  max-width: 100%;
  max-height: 11rem;
  object-fit: cover;
  margin: auto;
  border-radius: 2px;
}
.influencer__information {
  margin: 0 1rem;
}
.principal__data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0.5rem;
}

.principal__data .influencer__name {
  width: calc(100% - 30px);
  font-family: 'poppinsregular';
  font-size: 16pt;
}
.influencer__username,
.influencer__category {
  font-family: 'poppinsregular';
}
.influencer__title {
  font-family: 'poppinsmedium';
  color: var(--defaultPurple);
  font-size: 16px;
}

.influencer__icon {
  border-radius: 50% 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.influencer__icon i {
  color: var(--white);
  font-size: 1rem;
}
.influencer__icon.facebook {
  background-color: #2b4170;
  background: -moz-linear-gradient(top, #3b5998, #2b4170);
  background: -ms-linear-gradient(top, #3b5998, #2b4170);
  background: -webkit-linear-gradient(top, #3b5998, #2b4170);
}
.influencer__icon.instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.influencer__icon.twitter {
  background-color: #00acee;
}
.influencer__icon.tiktok {
  background-color: #010101;
}
.influencer__icon.linkedin {
  background-color: #0e76a8;
}
.influencer__icon.youtube {
  background-color: #ff0000;
}
/* todo: este texto debe ir el light */
.principal__data.influencer__name {
  font-size: 1.6rem;
}
/* todo: este texto debe ir el light */
.influencer__username {
  font-size: 1.2rem;
}
/* todo: este texto debe ir el light */
.influencer__category {
  font-size: 1.1rem;
  color: var(--defaultPurple);
}
.separator {
  border-bottom: 2px solid var(--defaultPurple);
  margin-bottom: 0.5rem;
}

/* * Categories component */
.categories__container {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.categories__container span {
  background-color: var(--defaultPurple);
  color: var(--white);
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.77rem;
}

/* data */
.influencer__data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.data__row {
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  align-items: center;
}
.data__row i {
  color: var(--defaultPink);
}
.data__row span {
  color: var(--defaultPurple);
}
.data__row a {
  color: var(--defaultPurple);
  transition: 0.2s all;
}
.data__row a:hover {
  color: var(--purpleHover);
  text-decoration: none;
}
.influencer__aboutme {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px 20px;
  border-bottom: 2px solid var(--defaultPurple);
  margin-bottom: 20px;
}
.influencer__aboutme h3,
.influencer__brands h3 {
  font-family: 'poppinsregular';
  font-size: 1.3rem;
  color: var(--defaultPurple);
  border-bottom: 2px solid var(--defaultPurple);
  padding: 0 1rem 5px;
  margin-bottom: 15px;
}
.influencer__aboutme p {
  text-align: left;
  font-size: 0.98rem;
  word-spacing: 0.05em;
  white-space: pre-wrap;
}
/* brand */
.influencer__brands {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.brands__logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.brands__logos img {
  width: 400px;
  margin: auto;
  margin-bottom: 20px;
}

/* Banner */
.banner {
  width: 100%;
}
.banner img {
  width: 100%;
  max-height: 22rem;
  object-fit: cover;
}
.influencer__container .col-md-9 {
  padding: 0;
}
.metrics {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  gap: 1rem;
  background-color: var(--defaultPurple);
  align-items: center;
}
.profileView_header .metrics-container {
  align-items: center;
}
.influencer__container .dashboardSquare_container {
  height: 4.5rem;
  justify-content: flex-start;
  padding-left: 0.5rem;
}
.influencer__container .dashboardSquare_title {
  font-size: 0.81rem;
  color: var(--defaultPurple);
  font-weight: bold;
}
.influencer__container .dashboardSquare_box-description {
  width: 62%;
}
.influencer__container .dashboardSquare_box-number {
  gap: 0.3rem;
}
.profileView_header .btn__porpose {
  min-width: 0;
  height: 100%;
}
.btn__porpose {
  font-family: 'poppinsregular';
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 12.3rem;
  border: none;
  background-color: var(--defaultPink);
  border-radius: 10px;
  padding: 0.5rem 0.8rem;
  color: white;
  font-size: 14px;
}
.btn__porpose i {
  font-size: 20px;
}
/* metrics menu */
.metrics__content {
  padding: 0 1rem;
  margin-top: 1rem;
}
.metrics__menu {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: var(--defaultPurple);
  height: 3rem;
  min-width: 100%;
  align-items: center;
  padding: 1rem;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 5px 10px #02020271;
  position: relative;
  justify-content: flex-start;
}
.metrics__menu button {
  background-color: transparent;
  border: none;
  color: var(--defaultPink);
}
.metrics__menu button.active {
  position: relative;
  color: var(--white);
}
.metrics__menu button.active::after {
  content: '';
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 15px;
  height: 15px;
  background-color: var(--defaultPurple);
  z-index: 2;
}
@media screen and (max-width: 990px) {
  .profileView_header .btn__porpose {
    width: 100%;
  }
  .data__row {
    word-break: break-all;
  }
}
@media screen and (max-width: 767px) {
  .profile__photo {
    margin: 1rem 4rem;
  }
  .principal__data {
    padding: 0 1rem;
    margin: 0 1.5rem;
    justify-content: flex-start;
  }
  .principal__data .influencer__name {
    width: 85%;
  }
  .metrics {
    flex-wrap: wrap;
  }
  .btn__porpose {
    width: 100%;
    justify-content: center;
  }
  .metrics__menu {
    gap: 0;
    font-size: 0.72rem;
  }
  .metrics__content {
    padding: 0;
    margin-top: 0;
    display: flex;
    justify-content: center;
  }
  .brands__logos img {
    width: 200px;
  }
}
@media screen and (max-width: 400px) {
  .brands__logos img {
    width: 100px;
  }
}
@media screen and (max-width: 380px) {
  .metrics__content {
    width: auto;
  }
}
