.paymentMethodList_container {
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.paymentMethodList_container thead {
  text-align: center;
  color: #50318b;
  font-size: 14px;
  line-height: 16px;
}
.paymentMethodList_btn {
  background: #28cae4 !important;
  color: #ffffff !important;
  margin: 10px;
}
