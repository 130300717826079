.analityc__content {
  width: calc(100% / 4);
  padding: 10px 10px 0;
}
.data__icon {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-content: center;
  background-color: rgba(219, 215, 215, 0.801);
  border-radius: 10px;
  color: var(--defaultPurple);
  flex: 0 0 auto;
}
.analityc__data__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}
.analityc__data__title h3 {
  margin-bottom: 0;
  font-size: 16px;
}
.analityc__data__title .data__icon {
  margin-right: 10px;
}
.analityc__data {
  display: grid;
  place-content: center;
  margin: auto;
}
.analityc__data {
  font-size: 2.3rem;
  color: var(--defaultPurple);
  padding: 1rem;
}
@media only screen and (max-width: 1200px) {
  .analityc__content {
    min-width: 10rem;
  }
  .analityc__data__title h3 {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 760px) {
  .analityc__content {
    width: 100%;
  }
}
