.alertRegister_container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: auto;
  font-family: 'poppinsregular';
}
.alertRegister_container.visible {
  opacity: 1;
  visibility: visible;
}
.alertRegister_container h3 {
  color: var(--defaultPurple);
  font-family: 'poppinsbold';
  font-size: 22px;
}
.alertRegister_alert {
  width: 90%;
  max-width: 600px;
  background-color: var(--white);
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 30px;
}
.alertRegister_header {
  border-bottom: 1px solid var(--defaultPurple);
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.alertRegister_header img {
  width: 80px;
}
.alertRegister_header button {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  background-color: var(--defaultPurple);
  color: var(--white);
  border: 0;
  border-radius: 50%;
  transition: 0.2s all;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertRegister_header button:hover {
  background-color: var(--purpleHover);
}
.alertRegister_body button {
  display: block;
  font-family: 'poppinsbold';
  transition: 0.2s all;
  background-color: var(--defaultPink);
  color: var(--white);
  margin: 20px auto 35px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 0;
}
.alertRegister_body {
  text-align: center;
}
.alertRegister_body button:hover {
  background-color: var(--purpleHover);
}
.alertRegister_body button:last-child {
  margin: 0 auto;
  background-color: var(--defaultPurple);
}
.alertRegister_body textarea {
  height: 80px !important;
  margin-bottom: 20px;
}
