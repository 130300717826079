.porposal {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--white);
}
.porposal__title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--defaultPink);
}
.porposal__data {
  display: flex;
  justify-content: space-between;
}
.networks__porposal {
  display: flex;
  gap: 1rem;
}
.detail__purpose video {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 768px) {
  .porposal__data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }
}
