.checkbox__option {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.campaign__goals .checkbox__option {
  width: 50%;
}
.checkbox__input {
  appearance: none;
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  appearance: none;
  border: 1px solid rgb(165, 162, 162);
  border-radius: 5px;
}
.checkbox__input:checked {
  background-color: var(--defaultPink);
  border: 1px solid var(--defaultPink);
}
.checkbox__text {
  font-size: 14px;
  color: var(--dark);
  margin-bottom: 0 !important;
}
.checkbox__number {
  border-radius: 5px;
  font-size: 8pt;
  padding: 0.2rem 0.3rem;
  background-color: #f1f1f5;
  color: #696974;
  font-weight: bold;
  margin-left: auto;
}
