.influencer-table {
  margin-bottom: 20px;
}
.influencer__row {
  display: table-row;
  border-bottom: 1px solid #ccc;
}
.influencer__row td {
  padding: 0.3rem;
  vertical-align: middle;
}
.influencer-table .influencer__img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 0;
}
.influencer__row .influencer__img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.influencer__row .influencer__name {
  font-size: 0.8rem;
  font-weight: bold;
}
.influencer__row .influencer__categories {
  display: flex;
  flex-wrap: wrap;
  /* margin: 0; */
  flex-direction: row;
  min-width: 11rem;
  margin: 1rem 0;
}
.influencer__row .category__container {
  background-color: #eee;
  margin-right: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.influencer__row .category__name {
  text-align: center;
  font-size: 13px;
}
.influencer__row .media {
  margin: 0;
  justify-content: center;
}
.influencer__row .icon-loup {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 5px;
}
.influencer__row .media__viewers {
  font-size: 0.8rem;
  margin: 0;
}
.influencer-table .influencer__followers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.influencer-table .influencer__followers .media {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 3px;
}
.influencer-table .influencer__followers .media .network__circle {
  margin-right: 2px;
}
.influencerRow__link {
  color: var(--defaultPurple);
  text-decoration: none;
  text-align: center;
  display: block;
}
.influencerRow__link:hover {
  color: var(--defaultPink);
  text-decoration: none;
}
