.post_row td {
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid rgb(187, 181, 181);
}
.post__networks {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
.post__btn__actions {
  display: flex;
  justify-content: space-around;
}
.trash__btn {
  border: none;
  background-color: var(--defaultPurple);
  width: auto;
  padding: 0 0.8rem;
  border-radius: 50px;
}
.trash__btn i {
  color: var(--white);
}
.trash__btn:hover {
  background-color: var(--purpleHover);
  box-shadow: 0px 0px 5px rgba(149, 149, 149, 0.349);
}
