/* paginator styles */
.paginator {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.paginator button {
  color: var(--white);
  background-color: var(--defaultPurple);
  border: none;
  border-radius: 10px;
  width: 7rem;
  padding: 0.4rem 1rem;
}
.pages {
  border-radius: 10px;
  border: 1px solid var(--defaultPurple);
  display: flex;
}
.pages button {
  background-color: var(--white);
  color: var(--defaultPink);
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  border-radius: 9px;
}
.pages button:hover {
  background-color: var(--defaultPink);
  color: var(--white);
}
.pages button.active {
  background-color: var(--defaultPurple);
  color: var(--white);
}
@media only screen and (max-width: 767px) {
  .paginator button {
    padding: 0.5rem;
    font-size: 0.7rem;
    width: auto;
  }
}
