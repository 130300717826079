.metrics__data {
  margin-top: 1rem;
  padding: 0 1rem;
  min-width: 100%;
}
.analytics {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.recent__posts {
  margin-bottom: 2rem;
  /* border-bottom: 2px solid rgba(194, 189, 189, 0.753); */
}
.cover {
  max-width: 100%;
  margin-bottom: 2rem;
}
.cover img {
  width: 100%;
}
.analitycs__title,
.recent__posts__title,
.audience__title {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-bottom: 1rem;
}
.analitycs__title h3,
.recent__posts__title h3,
.audience__title h3 {
  font-size: 1.5rem;
  margin-bottom: 0;
  color: var(--defaultPurple);
}
.analitycs__title span,
.recent__posts__title span,
.audience__title span {
  color: var(--gray);
}
.analitycs__container {
  display: flex;
  justify-content: flex-start;
}
.separator__vertical {
  border-left: 2px solid rgba(194, 189, 189, 0.753);
}
.analitycs__container .analityc__content {
  border-left: 2px solid rgba(194, 189, 189, 0.753);
  border-bottom: 2px solid rgba(194, 189, 189, 0.753);
}
.analitycs__container .analityc__content:nth-child(1) {
  border-left: 0;
}

/* profile header */
.profile__header {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  color: black;
  border-radius: 0 0 20px 20px;
}

.profile__banner {
  width: 100%;
  height: 200px;
  max-height: 250px;
  overflow: hidden;
  position: relative;
}
.profile__banner.no-cover {
  height: 100px;
}

.profile__banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile__info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  align-items: center;
  max-height: 7rem;
}

.profile__image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  border: 5px solid white;
  position: relative;
  top: -50px;
  left: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
}

.profile__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2rem;
}

.profile__stats {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.profile__stat {
  background-color: var(--defaultPink);
  padding: 0.5rem 1rem;
  border-radius: 7px;
  color: var(--white);
}
.profile__update-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.profile__update-button button {
  border: 1px solid var(--white);
}

@media only screen and (max-width: 1200px) {
  .metrics__data .audience__content {
    width: 13rem;
    height: 20rem;
  }
  .metrics__data .statistic__container {
    gap: 1rem;
  }
  .metrics__data .statistic__range {
    font-size: 0.72rem;
  }
}
@media screen and (max-width: 1000px) {
  .analitycs__container {
    flex-wrap: wrap;
    border-bottom: 0;
  }
  .analitycs__container .analityc__content {
    width: 50%;
  }
  .analitycs__container .analityc__content:nth-child(odd) {
    border-left: 0;
  }
  .analityc__data__title {
    justify-content: flex-start;
  }
  .analityc__data__title .data__icon {
    margin-right: 10px;
  }
}
@media screen and (max-width: 780px) {
  .metrics__data .audience__content {
    width: 15rem;
    height: 20rem;
    margin: auto;
  }
  .metrics__data .statistic__container {
    gap: 0rem;
  }
  .audience {
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .profile__header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  .profile__banner {
    height: 150px;
    max-height: 150px;
  }

  .profile__info {
    flex-direction: column;
    align-items: center;
    max-height: none;
    position: relative;
    top: -51px;
  }

  .profile__image {
    width: 120px;
    height: 120px;
    top: 0;
    left: 0;
    margin-bottom: 1rem;
  }

  .profile__text {
    align-items: center;
    text-align: center;
    margin-left: 0;
  }

  .profile__stats {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .profile__stat {
    font-size: 0.9rem;
    padding: 0.3rem 0.8rem;
  }
}
@media screen and (max-width: 500px) {
  .analitycs__container .analityc__content {
    width: 100%;
  }
  .analitycs__container .analityc__content:nth-child(even) {
    border-left: 0;
  }
}
