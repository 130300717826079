.porposal__row {
  display: table-row;
  border-bottom: 1px solid #ccc;
}
.list__porpose__container .porposal__row a {
  background-color: transparent;
  font-weight: bold;
  color: var(--white);
  font-size: 1.1rem;
  border-radius: 10px;
  background-color: var(--defaultPink);
}
.list__porpose__container .porposal__row a:hover {
  box-shadow: none;
  background-color: var(--pinkHover);
  color: var(--white);
}

.list__porpose__container .porposal__row button {
  background-color: transparent;
  font-weight: bold;
  color: var(--defaultPink);
  font-size: 1.1rem;
  border: none;
}
.list__porpose__container .porposal__row button:hover {
  box-shadow: none;
  background-color: transparent;
  color: var(--defaultPurple);
}
.list__porpose__container .porposal__row .status__cancelled {
  background-color: transparent;
  color: rgba(243, 47, 47, 0.812);
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.1rem;
}
.porposal__row td {
  padding: 1rem;
  vertical-align: middle;

  text-align: center;
}
.porposal__data__main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.porposal__data__main img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}
.porposal__row .networks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.porposal__row .networks img {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  object-fit: cover;
}
.porposal__row .porposal__status__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  justify-content: center;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  vertical-align: middle;
}

.view__detail {
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 767px) {
  .porposal__data__main {
    gap: 1rem;
  }
  .porposal__row td {
    padding: 1rem 0.4rem;
    font-size: 0.75rem;
  }
  .porposal__data__main img {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    object-fit: cover;
  }
  .porposal__row .networks {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
  }
}
