.tabs {
  border: none;
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
}
.tab {
  border: none;
  background-color: rgba(219, 218, 218, 0.493);
  font-size: 0.8rem;
  color: var(--defaultPurple);
  transition: all 0.2s ease-in-out;
  border-radius: 8px 8px 0 0;
  padding: 0.4rem 1rem;
  box-shadow: 0px -5px 5px rgba(20, 20, 20, 0.103);
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.tabs .active {
  background-color: rgb(255, 255, 255);
  color: var(--defaultPurple);
  font-size: 0.85rem;
}
.campaign__form {
  display: flex;
  width: 100%;
  gap: 1.5rem;
}
.form__content {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  min-height: 26.65rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.596);
  background-color: var(--white);
  justify-content: space-between;
}

.form__content {
  padding: 1rem;
  padding-right: 2rem;
}
.form__column {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.input__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
}
.inputdate__container {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  width: 100%;

  gap: 1rem;
}
.input__date {
  flex: 1;
  padding: 0;
}
.campaign__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#campaign_description {
  height: 4rem;
  overflow: auto;
  resize: none;
}
#campaign_deliverables {
  max-height: 12.56rem;
  height: 12.56rem;
}
#description__target {
  height: 9.25rem;
  max-height: 9.25rem;
}
#campaign__hashtag {
  margin-top: 1.5rem;
}
.input__group label {
  color: var(--defaultPink);
  font-size: 0.8rem;
}
.button__container {
  display: flex;
  gap: 1rem;
  align-self: flex-end;
}
.button__container button {
  outline: none;
  border: none;
  background-color: var(--defaultPurple);
  color: var(--white);
  width: 10rem;
  border-radius: 5px;
  padding: 0.3rem;
}
/* .button__container a {
  outline: none;
  border: none;
  background-color: var(--defaultPurple);
  color: var(--white);
  width: 10rem;
  border-radius: 5px;
  padding: 0.3rem;
  text-decoration: none;
  text-align: center;
}
.button__container a:hover {
  text-decoration: none;
  color: var(--white);
} */

.hashtags__pills__container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  border-radius: 5px;
  align-items: flex-start;
  overflow: auto;
  max-height: 8rem;
}
.hashtag__pill {
  color: var(--defaultPink);
  background-color: #fcd8eb;
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
}
.campaign__block {
  border: 0.5px solid rgb(228, 223, 223);
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.brands__targets {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.target {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.target__title {
  text-align: center;
  font-size: 1rem;
  color: var(--defaultPink);
  margin: 0;
}
.target__content {
  width: 100%;
  margin: auto;
}
.campaign__block .block__title {
  text-align: center;
  font-size: 1rem;
  color: var(--defaultPurple);
}
.networks {
  display: flex;
  flex-wrap: wrap;
  /* gap: 0.4rem; */
  justify-content: space-between;
}
.ubication__target {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 10rem;
  /* overflow: auto; */
  scrollbar-color: var(--white);
}

.ubication__target::-webkit-scrollbar {
  background-color: rgba(209, 209, 209, 0.61);
  max-width: 7px;
  border-radius: 2px;
}
.ubication__target::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--defaultPink);
}
.ubication__target .demographic__ubication {
  max-width: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  gap: 1rem;
}
.ubication__target .form-group {
  width: 50%;
}
.ubication__target .form-group.country {
  width: 100%;
}
.campaign__form .form-group .SelectSearch_container.line {
  border-radius: 10px;
  border: 2px solid rgb(228, 223, 223);
}
.campaign__form .form-group .SelectSearch_container.line input {
  padding: 0 0.5rem;
}
.campaign__form .form-group .SelectSearch_container.line i {
  right: 5px;
}

.cityOrCountry {
  align-items: center;
}
.buttons__container {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.buttons__container button {
  border: none;
  color: white;
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
  text-transform: uppercase;
}
.button__container button.disable {
  background-color: var(--gray);
}
.button__container a.disable {
  background-color: var(--gray);
}
.buttons__container :nth-child(1) {
  background-color: var(--defaultPink);
}
.buttons__container :nth-child(3) {
  background-color: var(--defaultPurple);
}
.location__container {
  display: flex;
  flex-wrap: wrap;
  max-height: 100px;
  overflow: auto;
  align-content: flex-start;
}

.location__pills {
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  color: var(--white);
  background-color: var(--defaultPink);
  font-size: 10pt;
  margin: 0 3px 3px 0;
}
.pill__btn {
  border: none;
  background-color: transparent;
  color: white;
}
.ubication__target label {
  color: var(--defaultPink);
  font-weight: bold;
}
.ubication {
  display: flex;
  align-items: center;
  align-items: baseline;
}
.delete__ubication {
  display: grid;
  place-content: center;
  border: none;
  height: 12px;
  width: 12px;
  text-align: left;
  background-color: var(--defaultPink);
  border-radius: 50%;
  padding: 0.2rem;
  margin: 0 0.2rem;
  color: var(--white);
  font-size: 0.5em;
}
.btn__back {
  background-color: var(--defaultPink);
  border-radius: 5px;
  border: none;
  color: var(--white);
  padding: 0.2rem 1rem;
  margin-top: 10px;
}
.add__ubication {
  display: grid;
  height: 25px;
  width: 25px;
  place-content: center;
  border: none;
  font-size: 1rem;
  background-color: var(--defaultPink);
  border-radius: 50px;
  padding: 0.8rem;
  align-self: center;
  color: var(--white);
  margin: 1rem 0;
}

.campaign__block .keywords__title {
  color: var(--defaultPink);
}
.filter__subtitle {
  font-size: 11.5pt;
  text-align: center;
  color: var(--defaultPink);
  margin-bottom: 1rem;
}
.influencer__profile {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 0.3rem;
}
.influencer__filter__section {
  /* max-width: 1rem; */
  height: 9.6rem;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex-direction: column;
}
.campaign__goals {
  display: flex;
  flex-wrap: wrap;
}
.influencer__gender {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 0;
}
#influencer_age_range .filter__subtitle {
  margin-bottom: 0;
}
.influencer__age {
  padding: 0 1rem;
}
.campaign__goals .checkbox__option {
  gap: 0.3rem;
  margin-bottom: 0.7rem !important;
}
.influencer__score {
  margin: 1rem auto;
}
.influencer__ranges {
  margin: 0 5rem;
}
.influencer__ranges .values__minmax {
  font-size: 0.9rem;
}
.influencer__ranges .filter__subtitle {
  margin-bottom: 0.5rem;
}
.influencer__ranges .values {
  margin-top: 0;
}
.button__container .publish__breif {
  background-color: var(--defaultPink);
}
.check__tab {
  border-radius: 100px;
  background-color: var(--defaultPurple);
  color: var(--white);
  width: 20px;
  height: 20px;
  padding: 5px;
  display: grid;
  place-content: center;
}

@media screen and (max-width: 767px) {
  .campaign__form__container {
    width: 100%;
    padding: 0 1rem;
  }

  .tabs {
    margin-left: 0;
    flex-wrap: nowrap;
    gap: 0;
  }
  .tabs .active {
    font-size: 0.65em;
  }
  .tab {
    font-size: 0.6rem;
    padding: 0.3rem 0.6rem;
    border-radius: 10px 10px 0 0;
    margin: 0.2rem;
  }

  .button__container {
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
  }

  .button__container button {
    width: 7rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  .form__column {
    width: 100%;
  }

  .inputdate__container {
    flex-direction: column;
    gap: 1rem;
  }

  .campaign__form {
    flex-direction: column;
  }

  .form__content {
    min-height: auto;
    padding-right: 1rem;
    border-radius: 5px 5px 15px 15px;
    margin-bottom: 2rem;
  }

  .input__group {
    margin-bottom: 1rem;
  }

  .campaign__input {
    width: 100%;
  }
  .networks {
    gap: 0.6rem;
  }
  .influencer__profile {
    flex-wrap: wrap;
  }
  .influencer__ranges {
    margin: 0;
  }
  .campaign__goals {
    justify-content: center;
  }
}
