.breif {
  /* padding: 0 1rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--white);
}
.breif__title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: var(--defaultPink);
}
.breif .logo__container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.breif .brand__logo {
  max-width: 250px;
  max-height: 150px;
}
.breif .brand__data {
  display: flex;
  justify-content: space-between;
}
.breif .data__field {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.porposal__subtitle {
  font-size: 1rem;
  color: var(--defaultPurple);
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.data__text {
  font-size: 0.9rem;
}
.detail__purpose {
  margin-bottom: 1.5rem;
}
.two__details {
  display: flex;
  gap: 5rem;
}
.breif .porposal__text {
  line-height: 2rem;
}
.objectives__container,
.tags__container,
.cities__breif__container {
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;
  flex-wrap: wrap;
}
.gender__container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.8rem;
  flex-wrap: wrap;
}

.breif div.gender__text {
  border-radius: 15px;
  padding: 0 1rem;
  background-color: var(--defaultPink);
  color: var(--white);
  line-height: 2rem;
  width: auto;
}
.breif div.tag__text {
  border-radius: 15px;
  padding: 0 1rem;
  background-color: var(--defaultPurple);
  color: var(--white);
  line-height: 2rem;
  width: auto;
}

.breif div.porposal__text {
  border-radius: 15px;
  padding: 0 1rem;
  background-color: var(--defaultBlue);
  color: var(--white);
  line-height: 2rem;
  width: auto;
}
.breif .hashtag__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.breif .hashtag {
  padding: 0.3rem 1rem;
  background-color: #fcd8eb;
  color: var(--defaultPink);
  border-radius: 5px;
}
.breif .keywords__campaign {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.breif .networks__porposal {
  gap: 0.5rem;
}
.cities__breif__container .location__pills {
  color: var(--white);
  background-color: var(--mediumPurple);
  border-radius: 50px;
  padding: 0.4rem 1rem;
}

.button__confirm {
  background-color: var(--defaultPurple);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  margin-left: 0.5rem;
}

.button__confirm:hover {
  background-color: var(--purpleHover);
}

.button__cancel {
  background-color: var(--gray);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  margin-left: 0.5rem;
}

.button__cancel:hover {
  background-color: #717171;
}
@media only screen and (max-width: 600px) {
  .breif .brand__data {
    flex-direction: column;
    gap: 1rem;
  }
  .porposal__subtitle {
    margin-bottom: 0.5rem;
  }
}
