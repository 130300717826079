.socialNetworks_content {
  width: 100%;
}
.influencer__addSocialNetwork .socialNetworks_content {
  width: auto;
}
.influencer__addSocialNetwork .socialNetworks_open-modal {
  height: auto;
  padding: 0.5rem 1rem;
  margin: 0;
}

.socialNetworks_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  background-color: var(--modal);
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}
.socialNetworks_modal.show {
  opacity: 1;
  visibility: visible;
}
.socialNetworks_modal-content {
  width: 90%;
  max-width: 400px;
  padding: 15px;
  border-radius: 15px;
  margin: 30px auto;
  background-color: var(--white);
}
.socialNetworks_modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightPurple);
}
.socialNetworks_modal-header h3 {
  font-size: 20px;
  margin: 0;
}
.socialNetworks_close {
  width: 35px;
  height: 35px;
  padding: 0;
  font-size: 20px;
  border: 0;
  background-color: var(--defaultPurple);
  color: var(--white);
  transition: 0.2s all;
  border-radius: 10px;
}
.socialNetworks_close:hover {
  background-color: var(--purpleHover);
}
.socialNetworks_social-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 5px 10px 5px 0;
  border: 0;
  margin-top: 10px;
  border-radius: 10px;
  color: var(--white);
  transition: 0.2s all;
}
button.socialNetworks_social-button:hover {
  box-shadow: 0 3px 0 rgba(78, 78, 78, 0.9);
  transform: translateY(-3px);
}
.socialNetworks_social-button.facebook {
  background-color: #2b4170;
  background: -moz-linear-gradient(top, #3b5998, #2b4170);
  background: -ms-linear-gradient(top, #3b5998, #2b4170);
  background: -webkit-linear-gradient(top, #3b5998, #2b4170);
}
.socialNetworks_social-button.instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.socialNetworks_social-button.tiktok {
  background-color: #010101;
}
.socialNetworks_social-button.twitter {
  background-color: #00acee;
}
.socialNetworks_social-button.youtube {
  background-color: #ff0000;
}
.socialNetworks_social-icon {
  width: 45px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-right: 1px solid var(--white);
  margin-right: 10px;
}
.socialNetworks_open-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background-color: var(--defaultPink);
  color: var(--white);
  transition: 0.2s all;
  border: 0;
  border-radius: 10px;
  margin-bottom: 20px;
}
.socialNetworks_open-modal:hover {
  background-color: var(--pinkHover);
}
.socialNetworks_social {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.socialNetworks_networks-title {
  font-size: 22px;
}
.socialNetworks_networks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.socialNetworks_network {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  margin: 5px;
  color: var(--white);
  border-radius: 5px;
}
.socialNetworks_network i {
  margin-right: 5px;
}
