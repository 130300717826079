.new__campaign {
  font-size: 16px;
}
.new__campaign a,
.new__campaign button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--defaultPink);
  color: var(--white);
  font-size: 16px;
  padding: 0 10px;
  height: 40px;
  transition: 0.2s all;
  border-radius: 10px;
  border: none;
}
.new__campaign a:hover,
.new__campaign button:hover {
  background-color: var(--defaultPurple);
  color: var(--white);
  text-decoration: none;
}
.my__campaigns {
  background-color: var(--white);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 1px 1px 10px #20202027;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.campaigns__filter {
  margin: auto;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.campaigns__filter button {
  border: none;
  background-color: transparent;
  font-weight: bolder;
}
.campaigns__filter button:nth-child(2) {
  color: var(--defaultBlue);
}
.campaigns__filter button:nth-child(3) {
  color: var(--defaultPurple);
}
.campaigns__filter button:nth-child(4) {
  color: var(--defaultPink);
}
.campaigns__filter button.active {
  border-bottom: 2px solid;
  border-radius: 0 0 2px 2px;
}
.campaigns__view__options {
  display: flex;
  margin: 1.5rem 0;
}
.button__change__view {
  display: flex;
  gap: 1rem;
}
.button__change__view button {
  border: none;
  background-color: transparent;
  color: var(--defaultPurple);
  font-size: 1.1rem;
}
.button__change__view button.active {
  color: var(--defaultPink);
}
.my__campaigns__container {
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .new__campaign {
    font-size: 14px;
  }

  .new__campaign a {
    height: 36px;
    font-size: 14px;
    padding: 0 8px;
    border-radius: 8px;
  }

  .my__campaigns__container {
    gap: 0;
  }

  .my__campaigns__container .campaign__card {
    margin: auto;
    margin-bottom: 1rem;
  }

  .campaigns__filter {
    gap: 0;
    font-size: 0.92rem;
  }

  .button__change__view {
    flex-direction: column;
    gap: 0.5rem;
  }

  .button__change__view button {
    font-size: 1rem;
  }
}
