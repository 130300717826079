.campaign-detail-page .title-manager {
  flex-direction: column;
  align-items: flex-start;
}
.general__data {
  display: flex;
  font-size: 1rem;
  color: #7b7b7b;
  gap: 1rem;
  align-items: center;
}
.campaign__date,
.campaign__members {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.campaign__date i,
.campaign__members i {
  font-size: 1.2rem;
}
.campaign__detail__container {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgb(190, 190, 190);
  margin-bottom: 2rem;
}
/* Menu de opciones */
.options__campaign {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  background-color: var(--defaultPurple);
  height: 3rem;

  align-items: center;
  padding: 1rem;
  border-radius: 10px 10px 0 0;
  justify-content: flex-start;
}
.campaign__status .status__name {
  margin: 0;
}
.options__campaign a {
  margin-left: 1rem;
  background-color: transparent;
  border: none;
  color: var(--white);
}
.options__campaign a:hover {
  text-decoration: none;
  color: var(--defaultPink);
}
.options__campaign a.active {
  position: relative;
  color: var(--defaultPink);
}
.options__campaign a.active::after {
  content: '';
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 15px;
  height: 15px;
  background-color: var(--defaultPurple);
  z-index: 2;
}
.info__section {
  display: flex;
  width: 100%;
  /* min-width: 100%; */
  justify-content: space-between;
}
.finish__campaign__btn {
  color: var(--white);
  background-color: var(--defaultPink);
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
}
.finish__campaign__btn:hover {
  box-shadow: 1px 1px 10px #7b7b7b;
  background-color: var(--defaultPurple);
}

/* content */
.campaign__content {
  padding: 1rem 2rem;
}
.breif__content {
  padding: 0 10rem;
  margin-bottom: 2rem;
}
.breif__content .breif__actions a,
.breif__content .breif__actions button {
  text-decoration: none;
  color: var(--white);
  padding: 0.4rem 1rem;
  background-color: var(--defaultPink);
  border-radius: 8px;
}
.breif__content .breif__actions a:nth-child(2),
.breif__content .breif__actions button:nth-child(2) {
  background-color: var(--defaultPurple);
}
.breif__content .breif__actions a:hover,
.breif__content .breif__actions button:hover {
  box-shadow: 1px 1px 10px #949393;
  text-decoration: none;
  color: var(--white);
  background-color: var(--pinkHover);
}
.breif__content .breif__actions a:nth-child(2):hover,
.breif__content .breif__actions button:nth-child(2):hover {
  background-color: var(--purpleHover);
}

/* breif actions */
.breif__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.breif__actions button {
  background-color: var(--defaultPink);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
}
.breif__actions button:hover {
  box-shadow: 1px 1px 10px #949393;
  background-color: var(--defaultPurple);
}

/* creator actions */
.creator__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.creator__actions button {
  color: var(--white);
  background-color: var(--defaultPink);
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
}
.creator__actions button:nth-child(2) {
  background-color: var(--defaultPurple);
}

.creator__actions button:hover {
  box-shadow: 1px 1px 10px #949393;
  text-decoration: none;
  color: var(--white);
  background-color: var(--pinkHover);
}

.creator__actions button:nth-child(2):hover {
  background-color: var(--purpleHover);
}

@media (max-width: 767px) {
  .options__campaign {
    gap: 1rem;
  }
  .breif__content {
    padding: 0;
  }
  .info__section {
    flex-direction: column;
  }
}
@media (max-width: 400px) {
  .options__campaign a {
    font-size: 0.78rem;
  }
  .options__campaign {
    gap: 0;
  }
}
