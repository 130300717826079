.uploadVideo {
  display: flex;
  flex-direction: column;
}

.uploadVideo .block__title {
  font-size: 1rem;
  color: var(--defaultPink);
}

/* Estilos para el input de carga de video */
.upload__input {
  display: none;
}

.upload__container {
  border: 2px dashed rgba(77, 77, 77, 0.712);
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
}

.custom__upload {
  display: inline-block;
  padding: 10px 15px;
  background-color: var(--defaultPink);
  color: white;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

.custom__upload:hover {
  background-color: var(--defaultPinkHover);
}

.custom__upload:active {
  background-color: var(--defaultPinkActive);
}

.video__preview {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 1rem;
}
.video__preview__container {
  width: 100%;
  height: 100%;
  display: flex;
}
.preview__video {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 10px;
  object-fit: cover;
  position: relative;
}

.uploadVideo .drag-drop {
  border: 2px dashed var(--white);
  background-color: rgba(175, 174, 174, 0.582);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  color: rgba(112, 112, 112, 0.849);
  text-align: center;
}

.drag-drop i {
  font-size: 4rem;
  color: rgba(112, 112, 112, 0.692);
}

.preview__closeButton {
  border: none;
  position: relative;
  top: -5px;
  right: 11px;
  width: 15px;
  height: 15px;
  border: none;
  background-color: var(--defaultPink);
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 10px;
}

.preview__closeButton i {
  font-size: 0.6em;
}
