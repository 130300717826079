.managerLayout_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #f9fbfd;
}
.managerLayout_box-contain {
  transition: 0.3s all;
}
.managerLayout_box-contain-all {
  width: 100%;
}
.managerLayout_box-contain-resize {
  width: calc(100% - 220px) !important;
}
