.dashboardSquare_container {
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #ffffff;
  margin: 5px 0;
}
.dashboardSquare_box-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.dashboardSquare_box-description {
  width: calc(100% - 88px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dashboardSquare_title {
  font-family: 'roboto_monoregular';
  font-size: 13px;
}
.dashboardSquare_box-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboardSquare_number {
  font-family: 'roboto_monobold';
  font-size: 16px;
}
.dashboardSquare_box-percentage {
  background: #d8f5f9;
  color: #28cae4;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 5px;
}
.dashboardSquare_box-rating {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dashboardSquare_box-rating span {
  margin-right: 2px;
  color: #ebe300;
  font-size: 14px;
}

/* @media (max-width: 1200px) {
  .dashboardSquare_box-rating {
    display: none;
  }
}
@media (max-width: 767px) {
  .dashboardSquare_box-rating {
    display: flex;
  }
} */
