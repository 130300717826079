.post__table {
  margin-top: 2rem;
  width: 100%;
}

.post__table thead {
  border-bottom: 2px solid var(--defaultPurple);
  color: var(--defaultPurple);
}
.post__table thead th {
  min-width: 7rem;
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 2px solid var(--defaultPurple);
}
.postTable__container {
  overflow-x: auto;
}
