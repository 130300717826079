.selectOption_content {
  margin-bottom: 20px;
}
.selectOption_label {
  font-weight: bold;
  font-size: 16px;
}
.selectOption_list {
  display: flex;
  flex-wrap: wrap;
}
.selectOption_item {
  width: 160px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 10px 10px 0 0;
  border-radius: 10px;
  background-color: var(--white);
  color: var(--defaultPurple);
  border: 2px solid var(--defaultPurple);
  transition: 0.2s all;
  font-weight: 700;
  font-family: 'poppinssemibold';
  font-size: 16px;
  line-height: 20px;
}
.selectOption_item:hover {
  background-color: var(--lightPurple);
}
.selectOption_item.active {
  background-color: var(--defaultPurple);
  color: var(--white);
}
.selectOption_price {
  font-family: 'poppinssemibold';
  color: var(--defaultPink);
  font-size: 18px;
}
