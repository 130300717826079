.keywords {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}
.keywords__title {
  color: var(--defaultPurple);
  font-weight: bold;
  font-size: 0.9rem;
}
.pill {
  background-color: var(--defaultPink);
  border-radius: 13px;
  color: var(--white);
  font-size: 14px;
  margin: 3px;
  padding: 7px 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 0;
}
.pill:hover {
  background-color: var(--pinkHover);
}
.pill.selected {
  background-color: var(--defaultPurple);
  color: #fff;
}
