.footer_content .container {
  font-family: 'poppinsregular';
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ffffff;
}
.footer_title-menu {
  font-family: 'poppinssemibold';
  color: #ffffff;
  font-size: 16px;
}
.footer_menu {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.footer_menu a,
.footer_contact {
  color: #ffffff;
  font-size: 13px;
  transition: 0.2s all;
}
.footer_menu a {
  padding: 2px 0;
}
.footer_menu a:hover {
  color: #28cae4;
  text-decoration: none;
}
.footer_sharer {
  padding-bottom: 20px;
}
.footer_contact {
  margin-top: 10px;
}
.footer_contact li {
  padding: 2px 0;
}
.footer_copy {
  font-family: 'poppinsregular';
  color: #ffffff;
  text-align: center;
  font-size: 11px;
  padding: 10px 10px 8px;
  background: #000000;
}
.footer_copy a,
.footer_copy a:hover {
  color: #ffffff;
}
.footer_sharer {
  padding-top: 20px;
  text-align: center;
  color: #ffffff;
}
.footer_sharer > .footer_sharer-title  {
  color: red;
}
.footer_sharer-social {
  display: flex;
  justify-content: center;
}
.footer_sharer-social a {
  padding: 0 8px;
  color: #ffffff;
  transition: 0.2s all;
  margin-top: 7px;
  font-size: 18px;
}
.footer_sharer-social a:hover {
  color: #28cae4;
  text-decoration: none;
}
.footer_logo {
  width: 100%;
  max-width: 200px;
}
@media screen and (max-width: 1025px) {
  .footer_copy {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 770px) {
  .footer_content {
    text-align: center;
  }
  .footer_title-menu {
    margin-top: 10px;
    padding-top: 10px;
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
}
