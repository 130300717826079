.post_detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.post__photos__slider {
  width: 100%;
  /* aspect-ratio: 3/3; */
  margin-bottom: 25px;
}
.post__photos__slider .slick-track {
  height: 100%;
}
.post__photos__slider img {
  /* aspect-ratio: 4/3; */
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 0;
}
.post__photos__slider .slick-next:before,
.post__photos__slider .slick-prev:before {
  color: var(--defaultPink);
}

.post__slider::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: var(--defaultBlue);
  visibility: collapse;
}

.post__slider::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: var(--defaultPurple);
}
.post__slider::-webkit-scrollbar-thumb:hover {
  background-color: var(--purpleHover);
}

.post_detail .post__slider img {
  width: 100%;
  left: 0;
  position: sticky;
  object-fit: cover;
  border: 2px;
  scroll-snap-align: center;
  margin-bottom: 0;
}

.post_detail .caption {
  margin: 0.5rem 0;
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: #000;
  word-break: break-all;
}

.post__header {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.post__header .influencer__profile img {
  width: 28px;
  height: 28px;
  border-radius: 50px;
  margin-bottom: 0;
}

.post__header .influencer__profile span {
  font-weight: bold;
}

.post__video {
  height: 100%;
}
.post__video video {
  box-shadow: 0px 0px 10px rgba(184, 184, 184, 0.842);
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.buttons__actions {
  display: flex;
  width: 100%;
  gap: 0.3rem;
  margin-top: 1rem;
}

.buttons__actions button:nth-child(1) {
  background-color: var(--mediumPurple);
}
.buttons__actions button:nth-child(2) {
  background-color: var(--defaultPink);
}
.buttons__actions button:nth-child(3) {
  background-color: var(--defaultPurple);
}

.button__action {
  width: 100%;
  background-color: var(--defaultBlue);
  color: var(--white);
  border: none;
  border-radius: 10px;
  padding: 0.5rem 0.3rem;
}
.post__link {
  border-radius: 10px;
  padding: 0.5rem 1rem;
  background-color: var(--defaultPink);
  width: 100%;
  color: var(--white);
  text-align: center;
}
.post__link:hover {
  color: var(--white);
  background-color: var(--pinkHover);
}
.post__detail__content {
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
}
.input__network {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}
.input__icon {
  width: 40px;
  height: 40px;
  background-color: var(--defaultPurple);
  border-radius: 8px 0 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}
.input__network a {
  width: calc(100% - 40px);
}
.input__network input,
.input__network .input {
  width: calc(100% - 40px);
  height: 40px;
  border: 1px solid var(--defaultPurple);
  border-left: 0;
  border-radius: 0 5px 5px 0;
  padding: 0 10px;
}
.post__subtile {
  width: 100%;
  margin-top: 10px;
  color: var(--defaultPurple);
  font-size: 16px;
  margin-bottom: 0;
  text-align: left;
}
.input__network .input {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  color: var(--black);
}
.input__network a:hover {
  text-decoration: none;
}
.input__network .input:hover {
  color: var(--defaultPink);
}
