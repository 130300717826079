.card__data__container {
  min-width: 200px;
  height: 200px;
  background: rgb(245 245 245 / 80%);
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}
.card__data__container .influencer__profile .influencer_profile_photo {
  width: 40px;
  object-fit: cover;
  border: 2px solid var(--white);
  border-radius: 50px;
}

.card__data__container p {
  margin-bottom: 0;
}
.card__data__container .influencer__profile {
  align-items: center;
  width: 100%;
}
.card__data__container .post__link {
  padding: 0.2rem 1rem;
}
