.productsView_new {
  background: #db308b !important;
  color: #ffffff !important;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.productsView_new i {
  font-size: 20px;
  margin-right: 5px;
}
.productView_box-search {
  max-width: 300px;
}
.productView_box-search input {
  border: 1px solid #707070 !important;
  font-family: 'poppinslight';
}
.productView_box-search button {
  width: 40px;
  color: #db308b;
}
.productView_box-search button:hover,
.productView_box-search button:focus {
  background: #db308b;
  color: #ffffff;
  outline: red !important;
}
@media screen and (max-width: 1025px) {
  .productView_box-search {
    margin-bottom: 10px;
  }
}
