.managerMenu_box {
  border-top: 10px solid #50318b;
  transition: 0.3s all;
  overflow: hidden;
  background: #ffffff;
}
.managerMenu_box-visible {
  width: 220px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.managerMenu_box-hidden {
  width: 0;
}
.managerMenu_container {
  width: 220px;
  height: 100%;
  overflow: auto;
}
.managerMenu_circle-mark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 10px;
}
.managerMenu_circle-mark img {
  width: 100%;
  height: 100%;
}
.managerMenu_information {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 40px;
}
.managerMenu_information h6 {
  margin: 0;
  width: 100%;
  text-align: center;
  padding: 0 10px;
  color: #50318b;
}
.managerMenu_menu {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin-bottom: 20px;
}
.managerMenu_menu a {
  height: 40px;
  transition: 0.2s all;
  display: flex;
  align-items: center;
}
.managerMenu_menu a:hover {
  text-decoration: none;
  color: #28cae4;
}
.managerMenu_menu span {
  margin-right: 14px;
}
.managerMenu_btn-manager {
  background: #28cae4 !important;
  color: #ffffff !important;
  display: block !important;
  margin: auto;
  width: 80%;
  margin-bottom: 30px;
}
.managerMenu_btn-manager:hover {
  background: #0ea8c1 !important;
}
a.managerMenu_active {
  color: #28cae4;
}
.managerMenu_notification {
  position: relative;
}
.managerMenu_notification .circle-notification {
  top: -7px;
  left: 10px;
}
.managerMenu_doc {
  font-size: 14px;
  text-align: center;
}
.managerMenu_mobile-menu {
  display: none;
}
@media screen and (max-width: 1025px) {
  .managerLayout_box-contain .container {
    margin-top: 60px;
  }
}
@media screen and (max-width: 680px) {
  .managerMenu_box {
    position: fixed;
    z-index: 11;
    height: 100%;
  }
  .managerMenu_box-visible {
    box-shadow: 8px 0 10px rgba(0, 0, 0, 0.3);
  }
  .managerLayout_box-contain {
    width: 100% !important;
  }
  .managerMenu_mobile-menu {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: 0;
    color: #db308b;
    font-size: 20px;
  }
}
