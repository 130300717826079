.slideOffer_container {
  width: calc(100% - 10px);
  height: 315px;
  display: block;
  position: relative;
  margin: 0 5px 20px;
  font-family: 'poppinsregular';
  background: #f2f2f2;
}
.slideOffer_image {
  width: 125px;
  height: 125px;
  object-fit: cover;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.slideOffer_info {
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.slideOffer_name {
  font-family: 'poppinssemibold';
  font-size: 23px;
  margin-bottom: 20px;
  color: #000000;
}
.slideOffer_description {
  width: 75%;
  font-size: 13px;
  margin-bottom: 20px;
  color: #666a6e;
}
.slideOffer_price {
  font-size: 20px;
  color: #50318b;
  font-family: 'poppinssemibold';
}
.slideOffer_price-promo {
  color: #adb2b7;
  text-decoration: line-through;
  font-size: 16px;
}
@media screen and (max-width: 500px) {
  .slideOffer_image {
    width: 125px;
    height: 125px;
  }
}
@media screen and (max-width: 330px) {
  .slideOffer_image {
    width: 100px;
    height: 100px;
  }
  .slideOffer_price {
    font-size: 18px;
  }
  .slideOffer_price-promo {
    font-size: 15px;
  }
}
