.campaign__card {
  display: flex;
  flex-direction: column;
  height: 15rem;
  max-height: 15rem;
  padding: 1rem;
  border-radius: 18px;
  box-shadow: 1px 1px 10px #7b7b7b42;
  /* margin: auto;
  margin-top: 1rem; */
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.campaign__card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
}
.logo__container {
  display: grid;
  place-content: center;
}
.logo__container img {
  width: 5rem;
  height: 4rem;
  object-fit: scale-down;
}
.campaign__information {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.4rem;
}
.campaign__networks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.campaign__networks img {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
}
.campaign__status {
  font-size: 0.9rem;
  background-color: var(--defaultBlue);
  padding: 0.3rem 0.5rem;
  width: 6rem;
  max-width: 6rem;
  border-radius: 10px;
  text-transform: uppercase;
  color: var(--white);
  display: grid;
  place-content: center;
}
.status__name {
  text-align: center;
  font-size: 0.8rem;
}

.ancore__card:hover {
  text-decoration: none;
  color: var(--defaultPurple);
}
.ancore__card:hover .campaign__card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.ancore__card:hover .campaign__card__name {
  text-decoration: none;
  color: var(--defaultPurple);
}

.campaign__card__name {
  text-align: center;
  font-size: 1.5rem;
  /* margin: 0.5rem 0; */
  color: var(--defaultPink);
  font-weight: bold;
  padding: 0.3rem;
}
.campaign__card__name:hover {
  text-decoration: none;
  color: var(--defaultPurple);
}
.campaign__footer {
  display: flex;
  justify-content: space-between;
}
.campaign__footer i {
  font-size: 1rem;
  color: gray;
  margin-right: 0.3rem;
}
.campaign__dates,
.influencers__campaign {
  color: gray;
  font-size: 0.9rem;
}
@media (max-width: 1200px) {
  .campaign__networks img {
    width: 1.2rem;
    height: 1.2rem;
  }
  .campaign__status {
    font-size: 0.7rem;
    width: auto;
    padding: 0.3rem 0.3rem;
  }
}
@media (max-width: 380px) {
  .campaign__information {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
