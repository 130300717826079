.title {
  color: var(--defaultPurple);
  font-weight: bold;
  font-size: 0.9rem;
}
.slider {
  max-width: 100%;
}
.values__minmax {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--defaultPurple);
}

.values {
  margin-top: 1rem;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
}
.range {
  max-width: 100%;
}
.multi-range-slider .bar-left {
  box-shadow: none;
}
.multi-range-slider .thumb::before {
  box-shadow: none;
  border: solid 2px white;
}
.multi-range-slider .thumb .caption * {
  display: none;
}
.multi-range-slider .bar-inner {
  box-shadow: none;
  border: none;
}
.multi-range-slider .bar-right {
  box-shadow: none;
}
