.openImage_container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f0f2f8;
  border: 0;
  margin-bottom: 10px;
}
.openImage_container-inline {
  width: 100%;
  border: 1px solid #f0f2f8;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}
.openImage_container .openImage-box-icon-up {
  margin-bottom: 10px;
}
.openImage_container-inline .openImage-box-icon-up {
  margin-right: 10px;
}
.openImage-box-icon-up {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #dddfe7;
  padding: 5px;
  color: #f0f2f8;
}
.openImage-box-icon-up div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.openImage-icon-up-1 {
  border: 4px solid #dddfe7;
  padding: 2px;
}
.openImage-icon-up-2 {
  justify-content: center;
  align-items: center;
  background: #dddfe7;
}
.openImage_title {
  font-size: 13px;
  color: #43425d;
}
.openImage_view-doc {
  height: 80px;
  position: relative;
}
.openImage_container-inline.preview,
.openImage_container.preview,
.openImage_view-doc.preview {
  height: 140px;
}
.openImage_view-doc a {
  margin-left: 5px;
  color: #50318b;
  transition: 0.2s all;
  position: relative;
  z-index: 5;
}
.openImage_view-pdf a {
  color: #50318b;
  transition: 0.2s all;
  position: relative;
  z-index: 5;
}
.openImage_view-doc a:hover,
.openImage_view-pdf a:hover {
  color: #17b6d0;
  text-decoration: none;
}
.openImage_view-pdf {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f0f2f8;
  border: 0;
  margin-bottom: 10px;
  position: relative;
}
.openImage_container-preview {
  position: absolute;
  z-index: 0;
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  background-size: contain !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  opacity: 0.3;
  border-radius: 3px;
}
