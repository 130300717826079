.saleViewList_content {
  padding: 10px;
  margin-bottom: 20px;
}
.saleViewList_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
  border-bottom: 1px solid #dadfe2;
}
.saleViewList_header button {
  height: 25px;
  background: 0;
  border: 0;
  border-bottom: 2px solid #ffffff;
  font-family: 'poppinsmedium';
  font-size: 13px;
  transition: 0.2s all;
}
.saleViewList_header button:focus {
  outline: none;
}
.saleViewList_header button:hover {
  opacity: 0.75;
}
.saleViewList_btn-new {
  color: #db308b;
}
.saleViewList_btn-new.active {
  border-bottom: 2px solid #db308b;
}
.saleViewList_btn-process {
  color: #2aa9db;
}
.saleViewList_btn-process.active {
  border-bottom: 2px solid #2aa9db;
}
.saleViewList_btn-approved {
  color: #50318b;
}
.saleViewList_btn-reject {
  color: red;
}
.saleViewList_btn-approved.active {
  border-bottom: 2px solid #50318b;
}
.saleViewList_btn-end {
  color: #1f1f1f;
}
.saleViewList_btn-end.active {
  border-bottom: 2px solid #1f1f1f;
}
.saleViewList_btn-reject.active {
  border-bottom: 2px solid red;
}
@media screen and (max-width: 540px) {
  .saleViewList_header {
    height: auto;
    flex-wrap: wrap;
  }
}
