.slideCategory_box {
  width: 250px;
  height: 200px;
  background: #3e3e3e;
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  margin: 10px auto 25px;
}
.slideCategory_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slideCategory_name {
  width: 100%;
  height: 70px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #50318B;
  background: rgba(255,255,255,.8);
  transition: .2s all;
  padding: 0 10px;
  z-index: 1;
}
.slideCategory_box:hover > .slideCategory_name {
  background: rgba(80, 49, 139, .8);
  height: 100%;
  color: #ffffff;
}
@media screen and (max-width: 1024px) {
  .slideCategory_box {
    width: 160px;
    height: 128px;
    margin: 10px 10px 15px;
  }
  .slideCategory_name {
    font-size: 12px;
    height: 50px;
  }
}