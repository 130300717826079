.influencerTable__title {
  text-align: center;
  min-width: 5rem;
  padding: 0.3rem;
  color: var(--defaultPurple);
}
.influencerTable__head {
  border-bottom: 2px solid var(--defaultPurple);
}
@media only screen and (max-width: 767px) {
  .influencer-table {
    width: 100%;
  }

  .influencerTable__title {
    min-width: 6rem;
  }
}
