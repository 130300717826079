.soldBy_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 3px solid #f6f7f8;
  border-radius: 5px;
  transition: 0.2s all;
  margin-bottom: 30px;
}
.soldBy_container:hover {
  border: 3px solid #e0e0e0;
  text-decoration: none;
}
.soldBy_image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 40px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.soldBy_rating {
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 10px;
}
.soldBy_rating span {
  margin: 0 4px;
}
.soldBy_title-rating {
  font-family: 'poppinsregular';
  text-align: center;
  color: #939ca1;
}
.soldBy_number-rating {
  font-family: 'roboto_monobold';
  font-size: 18px;
  color: #2d3039;
}
