/* title */
.title__option {
  color: var(--defaultPurple);
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.inbox__chats {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.inbox__title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(224, 219, 219, 0.753);
}
.inbox__title h4 {
  font-size: 1.2rem;
  color: var(--defaultPink);
}
.inbox__buttons button {
  margin: 0 0.5rem;
  border: none;
  background-color: transparent;
  font-size: 0.7rem;
  padding: 0;
  font-weight: bold;
}

.inbox__buttons button:nth-child(1) {
  color: var(--defaultPurple);
}
.inbox__buttons button:nth-child(2) {
  color: var(--defaultBlue);
}
.inbox__buttons button:hover {
  text-decoration: underline;
}

.inbox__porpose,
.inbox__accept {
  box-shadow: 1px 1px 10px rgba(224, 219, 219, 0.753);
  padding: 1rem;
  min-height: 24rem;
  max-height: 24rem;
  background-color: var(--white);
  margin-bottom: 2rem;
  border-radius: 5px;
  overflow: auto;
}

.campaign__inbox__content .answers__buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .campaign__inbox__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .answers__buttons button {
    max-width: 6rem;
    font-size: 0.7rem;
  }
}

/* .proposal__content {
  display: flex;
  flex-direction: column;
}

.proposal__content button {
  text-align: right;
  align-self: flex-end;
  background-color: var(--defaultBlue);
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  color: var(--white);
}
.proposal__content button:hover {
  background-color: var(--blueHover);
} */

.btn__deliver__proposal {
  width: 100%;
  background-color: var(--defaultPurple);
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  color: var(--white);
}
.btn__deliver__proposal:hover {
  background-color: var(--purpleHover);
}
