.formLayout_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.formLayout_column {
  height: 100%;
}
.formLayout_left {
  width: 40%;
  order: 1;
}
.formLayout_right {
  width: 60%;
  background-image: url('../../../../../images/bg.jpg');
  background-position: center center;
  background-size: cover;
  order: 2;
}
.formLayout_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.formLayout_form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formLayout_logo {
  width: 250px;
  height: auto;
  margin: 0 auto 10px;
  display: block;
}
.form-register {
  width: 90%;
  max-width: 500px;
}
.form-register .form-row {
  margin-bottom: 5px;
}
.form-menu {
  margin: 15px auto;
}
.form-menu a,
.form-menu button {
  height: 40px;
  margin: 0;
}
.form-login {
  width: 350px;
}
.submenu-form {
  text-align: right;
  margin: 10px auto;
}
.submenu-form a {
  color: #000000;
  transition: 0.2s all;
}
.submenu-form a:hover {
  color: #db308b;
  text-decoration: none;
}
.menu-form a {
  color: #db308b;
}
.menu-form a:hover {
  color: #000000;
  text-decoration: none;
}
.formLayout_slider {
  width: 100%;
  height: 100%;
  position: relative;
  color: #ffffff;
}
.formLayout_slider p {
  font-size: 18px;
  font-family: 'poppinsregular';
}
.formLayout_slider h2 {
  font-size: 25px;
  font-family: 'poppinsbold';
}
.formLayout_slider .slick-slider {
  height: calc(100% - 35px);
}
.formLayout_slider .slick-list,
.formLayout_slider .slick-track,
.formLayout_slider .slick-slide,
.formLayout_slider .slick-slide div,
.formLayout_slide {
  height: 100%;
}
.formLayout_slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.formLayout_slide img {
  width: 90%;
  max-width: 800px;
}
.formLayout_slider .slick-dots {
  text-align: left;
  height: 35px;
  bottom: -35px;
  padding-left: 15px;
  background-color: #ffffff;
}
.formLayout_slider .slick-dots li,
.formLayout_slider .slick-dots li button {
  width: 10px;
  height: 10px;
}
.formLayout_slider .slick-dots li button:before {
  width: 10px;
  height: 10px;
  background: #b1b1b1;
  content: '';
  opacity: 1;
  transition: 0.2s all;
  border-radius: 50%;
}
.formLayout_slider .slick-dots li:hover button:before {
  background: #b1b1b1;
  opacity: 0.7;
}
.formLayout_slider .slick-dots li.slick-active button:before {
  background: #65b1e8;
}
@media screen and (max-width: 1023px) {
  .formLayout_container {
    flex-direction: column;
    overflow: auto;
    display: block;
  }
  .formLayout_column {
    width: 100%;
    height: auto;
  }
  .formLayout_left {
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .form-register,
  .form-login {
    width: 290px;
    margin-bottom: 20px;
  }
  .formLayout_slider .slick-list,
  .formLayout_slider .slick-track,
  .formLayout_slider .slick-slide,
  .formLayout_slider .slick-slide div,
  .formLayout_slide {
    height: auto;
  }
  .formLayout_right {
    padding-top: 20px;
  }
  .formLayout_slide {
    padding: 0 20px;
  }
  .formLayout_slider .slick-dots {
    bottom: 0;
    background-color: transparent;
  }
  .formLayout_slider .slick-dots li button:before {
    background: #ffffff;
  }
  .formLayout_slider .slick-dots li:hover button:before {
    background: #ffffff;
  }
}
@media screen and (max-height: 650px) and (min-width: 1024px) {
  .formLayout_left .formLayout_form {
    justify-content: flex-start;
    padding-top: 20px;
  }
}
@media screen and (max-width: 650px) {
  .form-register,
  .form-login {
    width: 90%;
  }
}
