.breif__campaign .breif {
  padding: 2rem 1rem;
}
.breif__campaign .breif .porposal__header {
  padding: 0;
}
.breif__campaign .porposal__response .breif {
  padding: 0;
}
.breif__campaign {
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  box-shadow: 1px 1px 10px rgb(226, 222, 222);
  background-color: var(--white);
  border-radius: 10px;
}
.porposal__body .breif .campaign__input {
  height: auto;
}
.breif__campaign .campaign__input {
  height: 10rem;
}
.interest__buttons {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  gap: 1rem;
}
.interest__buttons button {
  border: none;
  width: 10rem;
  padding: 0.3rem 1rem;
  border-radius: 8px;
  color: var(--white);
  text-transform: uppercase;
}
.interest__buttons button:nth-child(1) {
  background-color: var(--defaultPink);
}
.interest__buttons button:nth-child(2) {
  background-color: var(--defaultBlue);
}
#response-breif {
  height: 5rem;
}
.response__breif {
  display: flex;
  flex-direction: column;
}
.response__breif button {
  border: none;
  background-color: var(--defaultBlue);
  padding: 0.3rem 1rem;
  align-self: flex-end;
  width: 10rem;
  border-radius: 8px;
  text-transform: uppercase;
  color: var(--white);
}
