@font-face {
  font-family: 'poppinsblack';
  src: url('fonts/poppins-black-webfont.eot');
  src: url('fonts/poppins-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/poppins-black-webfont.woff2') format('woff2'),
    url('fonts/poppins-black-webfont.woff') format('woff'),
    url('fonts/poppins-black-webfont.ttf') format('truetype'),
    url('fonts/poppins-black-webfont.svg#poppinsblack') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppinslight';
  src: url('fonts/poppins-light-webfont.eot');
  src: url('fonts/poppins-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/poppins-light-webfont.woff2') format('woff2'),
    url('fonts/poppins-light-webfont.woff') format('woff'),
    url('fonts/poppins-light-webfont.ttf') format('truetype'),
    url('fonts/poppins-light-webfont.svg#poppinslight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppinsbold';
  src: url('fonts/poppins-bold-webfont.eot');
  src: url('fonts/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/poppins-bold-webfont.woff2') format('woff2'),
    url('fonts/poppins-bold-webfont.woff') format('woff'),
    url('fonts/poppins-bold-webfont.ttf') format('truetype'),
    url('fonts/poppins-bold-webfont.svg#poppinsbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppinsmedium';
  src: url('fonts/poppins-medium-webfont.eot');
  src: url('fonts/poppins-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/poppins-medium-webfont.woff2') format('woff2'),
    url('fonts/poppins-medium-webfont.woff') format('woff'),
    url('fonts/poppins-medium-webfont.ttf') format('truetype'),
    url('fonts/poppins-medium-webfont.svg#poppinsmedium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppinsregular';
  src: url('fonts/poppins-webfont.eot');
  src: url('fonts/poppins-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/poppins-webfont.woff2') format('woff2'),
    url('fonts/poppins-webfont.woff') format('woff'),
    url('fonts/poppins-webfont.ttf') format('truetype'),
    url('fonts/poppins-webfont.svg#poppinsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppinssemibold';
  src: url('fonts/poppins-semibold-webfont.eot');
  src: url('fonts/poppins-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/poppins-semibold-webfont.woff2') format('woff2'),
    url('fonts/poppins-semibold-webfont.woff') format('woff'),
    url('fonts/poppins-semibold-webfont.ttf') format('truetype'),
    url('fonts/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_monoregular';
  src: url('fonts/robotomono-regular-webfont.eot');
  src: url('fonts/robotomono-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/robotomono-regular-webfont.woff2') format('woff2'),
    url('fonts/robotomono-regular-webfont.woff') format('woff'),
    url('fonts/robotomono-regular-webfont.ttf') format('truetype'),
    url('fonts/robotomono-regular-webfont.svg#roboto_monoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_monobold';
  src: url('fonts/robotomono-bold-webfont.eot');
  src: url('fonts/robotomono-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/robotomono-bold-webfont.woff2') format('woff2'),
    url('fonts/robotomono-bold-webfont.woff') format('woff'),
    url('fonts/robotomono-bold-webfont.ttf') format('truetype'),
    url('fonts/robotomono-bold-webfont.svg#roboto_monobold') format('svg');
  font-weight: normal;
  font-style: normal;
}
