.generalSlider_container {
  position: relative;
}
.generalSlider_container .slick-dots {
  position: initial;
}
.generalSlider_container .slick-dots li {
  width: 10px;
  height: 10px;
}
.generalSlider_container .slick-dots li button:before {
  width: 10px;
  height: 10px;
  background: #b1b1b1;
  content: '';
  opacity: 1;
  transition: 0.2s all;
  border-radius: 50%;
}
.generalSlider_container .slick-dots li:hover button:before {
  background: #b1b1b1;
  opacity: 0.7;
}
.generalSlider_container .slick-dots li.slick-active button:before {
  background: #db308b;
}
.generalSlider_viewAll {
  color: #50318b;
  font-family: 'poppinssemibold';
  font-size: 12px;
  text-decoration: underline;
  position: absolute;
  right: 0;
  bottom: -10px;
  transition: 0.2s all;
}
.generalSlider_viewAll:hover {
  color: #db308b;
}
