.score__form {
  width: 100%;
  margin: 0 auto;
}
.score__form label {
  font-size: 20px;
  margin-right: 2px;
}
.score__form input[type='radio'] {
  display: none;
}
.score__form label {
  color: rgb(170, 166, 166);
}
.score {
  direction: rtl;
  unicode-bidi: bidi-override;
}
.score__form label:hover,
.score__form label:hover ~ label {
  color: var(--defaultPink);
}
.score__form input[type='radio']:checked ~ label {
  color: var(--defaultPink);
}
.score__title {
  font-size: 14px;
  font-family: 'poppinsmedium';
  color: #50318b;
  margin-bottom: 5px;
}
