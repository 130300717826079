.comments_box {
  width: 100%;
}
.comments_item {
  padding: 15px;
  border-bottom: 2px solid #c3c3c3;
}
.comments_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e8ea;
  margin-bottom: 5px;
  padding-bottom: 10px;
}
.comments_date {
  font-size: 12px;
  color: #07b4ec;
}
.comments_users {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.comments_users img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  margin: 0 10px 10px 0;
}
.comments_name {
  font-family: 'poppinsmedium';
  color: #50308b;
}
