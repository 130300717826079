:root {
  --defaultPurple: #50318b;
  --purpleHover: #331177;
  --bgCalendar: #eee5ff;
  --lightPurple: #e8dcff;
  --lightPurpleHover: #cebfeb;
  --mediumPurple: #927ebd;
  --defaultPink: #db308b;
  --pinkHover: #c02377;
  --defaultBlue: #28cae4;
  --blueHover: #28bbe4;
  --white: #ffffff;
  --gray: #858585;
  --lightGray: #d5d5d5;
  --modal: rgba(0, 0, 0, 0.75);
  --modalWhite: rgba(255, 255, 255, 0.75);
  --black: #000000;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  font-family: 'poppinsregular';
}
#root {
  height: 100%;
}
#modal {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: 'poppinsbold';
}
.color-purple {
  color: #50318b;
}
.background-purple {
  background: #50318b;
}
.color-blue {
  color: #28cae4;
}
.background-blue {
  background: #28cae4;
}
.color-gray {
  color: #939ca1;
}
.background-gray {
  background: #939ca1;
}
.color-fuchsia {
  color: #db308b;
}
.background-fuchsia {
  background: #db308b;
}
.color-darkgray {
  color: #5d6d86;
}
.background-darkgray {
  background: #5d6d86;
}
.color-lightgray {
  color: #eff0f4;
}
.background-lightgray {
  background: #eff0f4;
}
.color-lightblue {
  color: #d8f5f9;
}
.background-lightblue {
  background: #d8f5f9;
}
.color-yellow {
  color: #ebe400;
}
.background-yellow {
  background: #ebe400;
}
.color-darkblue {
  color: #2aaadb;
}
.background-darkblue {
  background: #2aaadb;
}
.color-text {
  color: #b7b7b7;
}
.circle-notification {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 10px;
  background: #db308b;
  border: 1px solid #ffffff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-notification {
  top: -3px;
  right: -3px;
}
.menu-notification {
  top: -5px;
  left: 20px;
}
.menu-notification {
  position: relative;
}
.title-manager {
  color: #2aa9db;
  font-size: 29px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
}
.title-manager span {
  margin-right: 10px;
}
.title-manager a {
  margin-bottom: 0;
}
.background-white {
  background: #ffffff;
}
.hidden {
  display: none;
}
.btn-pymex {
  background: #50318b;
  color: #ffffff;
  margin-bottom: 20px;
}
.btn-pymex:hover {
  background: #331177;
  color: #ffffff;
}
.btn-pymex-2 {
  background: #db308b;
  color: #ffffff;
  margin-bottom: 20px;
}
.btn-pymex-2:hover {
  background: #bf2072;
  color: #ffffff;
}
.react-datepicker-wrapper {
  width: 100%;
}
.box-search {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.no-products {
  padding: 10px 0;
}
.center {
  text-align: center;
}
.align-items-center input[type='checkbox'] {
  margin-right: 5px !important;
}
.centerCenter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.btn.readonly {
  background-color: #b7b7b7;
  color: #ffffff;
}
.btn-bax {
  background-color: #50318b;
  color: #ffffff;
}
.btn-bax:hover {
  background-color: #331177;
  color: #ffffff;
}
.spinner-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.site_modal {
  width: 100%;
  height: 100%;
  background-color: var(--modal);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s all;
}
.site_modal.visible {
  opacity: 1;
  visibility: visible;
}
.modal_content {
  width: 90%;
  padding: 15px;
  background-color: var(--white);
  margin: 30px auto;
  border-radius: 15px;
}
.modal_content.short {
  max-width: 400px;
}
.modal_content.medium {
  max-width: 600px;
}
.modal_content.large {
  max-width: 800px;
}
.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--lightPurple);
}
.modal_title {
  font-family: 'poppinsbold';
  font-size: 20px;
}
.modal_header button {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--defaultPurple);
  border: 0;
  transition: 0.2s all;
  color: var(--white);
}
.modal_header button:hover {
  background-color: var(--purpleHover);
}
.modal_body label {
  margin-bottom: 0;
}
.modal_body .questions_input,
.modal_body .questions_select,
.modal_body .questions_textarea {
  margin-top: 0;
  margin-bottom: 10px;
}
.modal_body .questions_textarea {
  height: 80px;
}
.modal_save {
  padding: 8px 15px;
  background-color: var(--defaultPurple);
  color: var(--white);
  transition: 0.2s all;
  border: 0;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
}
.modal_save:hover {
  background-color: var(--purpleHover);
}
.profileView_btn-save {
  color: var(--white) !important;
  background: var(--pink) !important;
}
.profileView_btn-save:hover {
  background: var(--defaultPurple) !important;
}
@media screen and (max-width: 1025px) {
  .intercom-launcher-frame {
    bottom: 50px !important;
  }
  .intercom-launcher-badge-frame {
    bottom: 90px !important;
  }
}
@media screen and (max-width: 700px) {
  .intercom-launcher-frame {
    bottom: 90px !important;
  }
  .intercom-launcher-badge-frame {
    bottom: 130px !important;
  }
}
@media screen and (max-width: 576px) {
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .copy-button {
    font-size: 14px;
  }
}
