.referredView_description {
  font-family: 'poppinsbold';
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 25px;
  color: var(--gray);
}
.referredView_item {
  font-family: 'poppinssemibold';
  display: flex;
  text-align: left;
  align-items: center;
  color: var(--gray);
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}
.referredView_item img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 10px;
}
.referredView_social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.referredView_social button {
  margin-right: 5px;
}
.referredView_input-route {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-family: 'poppinsregular';
}
.referredView_input-route div {
  width: calc(100% - 120px);
  height: 45px;
  border: 1px solid var(--gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
}
.referredView_input-route button {
  width: 120px;
  height: 45px;
  border: 0;
  background-color: var(--defaultPurple);
  color: #ffffff;
  transition: 0.2s all;
  border-radius: 0 5px 5px 0;
}
.referredView_input-route button:hover {
  border: 0;
  background-color: var(--defaultPink);
  color: #ffffff;
}
.referredView_list-content {
  padding-top: 30px;
}
.referredView_list {
  padding-top: 20px;
  font-family: 'poppinsregular';
}
.referredView_list h3 {
  font-family: 'poppinsbold';
  color: var(--defaultPurple);
}
.referredView_referred {
  border: 1px solid var(--gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  color: var(--gray);
  transition: 0.2s all;
}
.referredView_referred:hover {
  color: var(--defaultPurple);
  text-decoration: none;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
  transform: translateY(-3px);
}
.referredView_referred img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
