.orderDetail_product-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px;
}
.orderDetail_img {
  width: 100px;
  height: 100px;
  margin: 5px;
  object-fit: contain;
}
.orderDetail_table thead {
  text-align: center;
  font-family: 'poppinsbold';
  color: #db308b;
}
.orderDetail_table tbody {
  text-align: center;
}
