.inbox_new-message {
	background: #DB308B !important;
	color: #ffffff !important;
	font-size: 14px;
	display: flex;
	align-items: center;
}
.inbox_new-message i {
	font-size: 20px;
	margin-right: 5px;
}