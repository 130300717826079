.detailSellerBuyer_continer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.detailSellerBuyer_box-content {
  margin: 10px;
  width: 50%;
  border: 1px solid #868e96;
  border-radius: 10px;
  padding: 10px;
}
.detailSellerBuyer_title {
  text-align: center;
  color: #868e96;
  text-align: center;
  font-family: 'poppinsmedium';
}
.detailSellerBuyer_box-user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.detailSellerBuyer-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 7px;
}
.detailSellerBuyer_text-name {
  color: #50308b;
  font-family: 'poppinsbold';
}
