.influencer__card {
  width: 100%;
  height: 100%;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  border-radius: 10px;
  min-width: 100%;
  transition: 0.2s all;
}
.influencer__card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  text-decoration: none;
}
.influencer__img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: #9db1bc;
  margin-top: 20px;
  margin-bottom: 10px;
}
.influencer__img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.influencer__info {
  max-width: 100%;
}
.influencer__info .influencer__name {
  font-family: 'poppinsbold';
  font-size: 12pt;
  color: black;
  font-weight: bold;
}
.influencer__name {
  font-size: 17pt;
  margin-bottom: 0.2rem;
}
.influencer__city {
  font-size: 11pt;
  font-weight: 400;
  color: #9db1bc;
}
.influencer__categories {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 3px 5px;
}
.category__container {
  background-color: #9db1bc46;
  border-radius: 4px;
  padding: 0.4rem;
  margin: 3px;
}
.category__name {
  font-size: 8.2pt;
  margin-bottom: 0;
  color: var(--black);
}
.influencer__social {
  border-top: 1px solid #9db1bcb9;
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
  color: var(--black);
}
.influencer__info .influencer__social {
  margin: 0;
}

.influencer__social .media {
  padding: 0.8rem 0;
  flex: 1;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}
.media .network__circle i {
  font-size: 12pt;
}
.media__viewers {
  margin-bottom: 0;
}
/* Estilos para dispositivos móv
iles */

@media only screen and (max-width: 1200px) {
  .influencer__card .media .network__circle {
    width: 24px;
    height: 24px;
  }
  .influencer__card .media .network__circle i {
    font-size: 12pt;
  }
}
@media only screen and (max-width: 767px) {
  .influencer__card {
    max-width: 100%;
    min-width: 100%;
  }

  .influencer__img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }

  .influencer__info .influencer__name {
    font-size: 10pt;
  }

  .influencer__city {
    font-size: 9pt;
  }

  .influencer__categories {
    flex-wrap: wrap;
    padding: 0.5rem 0.3rem;
  }

  .category__container {
    width: 4rem;
    padding: 0.3rem;
  }

  .category__name {
    font-size: 7pt;
  }

  .media {
    font-size: 0.6rem;
  }
}
