.statistic {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  gap: 0.5rem;
  justify-content: center;
}
.statistic__range {
  flex: 2;
  font-size: 0.82rem;
  text-align: center;
  color: var(--defaultPurple);
}

.statistic__bar {
  width: 100%;
  height: 0.5rem;
  background-color: #ddd;
  border-radius: 10px;
  position: relative;
  flex: 7;
}

.statistic__bar--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e3e6f7;
  border-radius: 10px;
}

.statistic__bar--progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--defaultPurple);
  border-radius: 10px;
}
