.orderView_menu {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.orderView_menu-button {
  width: 110px;
  height: 35px;
  color: #2aa9db;
  border: 0;
  background: #eff0f4;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orderView_menu-button:hover {
  color: #50318b;
  text-decoration: none;
}
.orderView_active {
  background: #ffffff !important;
}
.orderView_container {
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
