.campaign-post {
  margin-top: 1rem;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.campaign-post h2 {
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--defaultPurple);
}
.campaign-post img {
  margin-bottom: 1rem;
  width: 100%;
  object-fit: cover;
}
.post__campaign__header {
  display: flex;
  justify-content: space-between;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-background .modal__create__post {
  background-color: white;
  width: 550px;
  height: auto;
  max-height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: modal-animation 0.3s ease-in-out;
  border-radius: 10px;
}
.modal-background .modal__view__post {
  background-color: white;
  width: 550px;
  height: auto;
  max-height: 90%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: modal-animation 0.3s ease-in-out;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
}
.modal-background .modal__view__post .slick-prev {
  left: 0;
  z-index: 99;
}
.modal-background .modal__view__post .slick-next {
  left: 96%;
}

@keyframes modal-animation {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal__create__post {
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 100%;
}

.modal-background .modal__view__post .post_detail {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-width: 100%;
}

.create__post__form {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
}

/* Personalizar el scrollbar */
.create__post__form::-webkit-scrollbar,
.post_detail::-webkit-scrollbar,
.post__detail__content::-webkit-scrollbar {
  display: none;
}

.create__post__form h3 {
  color: var(--defaultPurple);
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

#description__post {
  min-height: 4rem;
}

.new__post__btn,
.back_btn,
.button_edit,
.modal__actions__btns button {
  color: var(--white);
  background-color: var(--defaultPink);
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
}
.new__post__btn:hover,
.back_btn:hover,
.button_edit:hover,
.modal__actions__btns button:hover {
  box-shadow: 1px 1px 10px #949393;
  text-decoration: none;
  color: var(--white);
  background-color: var(--pinkHover);
}
.photo__video {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.photo__video button {
  color: var(--white);
  background-color: var(--defaultPink);
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1rem;
  width: 100%;
}
.photo__video button:hover {
  box-shadow: 1px 1px 10px #949393;
  text-decoration: none;
  color: var(--white);
  background-color: var(--pinkHover);
}
.photo__video button:nth-child(3) {
  background-color: var(--defaultPurple);
}
.photo__video button:nth-child(3):hover {
  background-color: var(--purpleHover);
}
.upload__attatchment {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.modal__actions__btns {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.modal__actions__btns button {
  flex: 1;
}

.modal__actions__btns button:nth-child(1) {
  background-color: var(--defaultPurple);
}
.modal__actions__btns button:nth-child(1):hover {
  background-color: var(--purpleHover);
}
.modal__actions__btns button:nth-child(2) {
  background-color: var(--defaultBlue);
}
.modal__actions__btns button:nth-child(2):hover {
  background-color: var(--blueHover);
}
.close__btn {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 25px;
  right: 25px;
  color: var(--defaultPink);
  z-index: 10;
}
.post_detail .close__btn {
  position: absolute;
  top: -8px;
  right: -10px;
}
.close__btn i {
  font-size: 1.4rem;
  transition: all ease-in-out 0.2s;
}
.close__btn i:hover {
  animation: rotate-icon 0.3s ease-in-out;
}
.post_detail .close__btn i {
  font-size: 34px;
}
.post__status__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

@keyframes rotate-icon {
  0% {
    transform: rotate(15deg);
  }
  25% {
    transform: rotate(35deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
@media only screen and (max-width: 600px) {
  .new__post__btn,
  .back_btn,
  .button_edit,
  .modal__actions__btns button {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .modal-background .modal__view__post,
  .modal-background .modal__create__post {
    width: 87vw;
  }
}
.post__error_container {
  width: 200px;
  margin: auto;
  margin-top: 1rem;
}
