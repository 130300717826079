.influencersView {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: flex-start;
}
.influencerFilter {
  flex: 0;
  padding: 20px 25px 10px;
  background-color: white;
  border-radius: 13px;
  margin-bottom: 1rem;
}
.influencerList__container {
  flex: 1;
  width: 70%;
  padding: 0 1rem;
}
.view-buttons {
  display: flex;
  /* margin-right: 0.5rem; */
  border: none;
}
.view-buttons button {
  background: transparent;
  border: none;
  color: var(--defaultPurple);
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.2rem 0.5rem;
}
.view-buttons button.active {
  color: var(--purpleHover);
  font-weight: bold;
  background-color: #f1f3f4;
}
.changeView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #51318b4a;
  /* padding: 0.5rem 0; */
  margin-bottom: 0.8rem;
  align-items: center;
  border-radius: 8px;
  /* border: 1px solid #e5e5e5; */
}
.change__result {
  margin-left: 1rem;
  height: 100%;
  font-size: 0.8rem;
  color: var(--defaultPurple);
  font-weight: bold;
}

/* medias */
@media screen and (max-width: 767px) {
  .influencersView {
    display: block;
  }
  .influencerList__container {
    width: 100%;
  }
  .influencerFilter {
    margin: 0 0.7rem;
    margin-bottom: 1rem;
    padding: 1rem 1rem;
    padding-bottom: 0.2rem;
  }
  .influencerTable__container {
    width: 100%;
    overflow-x: auto;
  }
  .influencer__table__container {
    overflow-x: auto;
  }
}
