.slideProduct_container {
  width: 100%;
  max-width: 250px;
  height: 350px;
  background: #ffffff;
  padding: 10px;
  display: block;
  margin: 5px auto 25px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.2s all;
  position: relative;
  border: 2px solid #f0f0f0;
}
.slideProduct_container:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.slideProduct_container a {
  width: 100%;
  height: 100%;
  display: block;
  color: #262626;
  transition: 0.2s all;
}
.slideProduct_container a:hover {
  color: #50318b;
  text-decoration: none;
}
.slideProduct_image {
  width: 100%;
  height: calc(100% - 120px);
  position: relative;
}
.slideProduct_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slideProduct_info {
  display: flex;
  align-items: center;
  height: 100px;
}
.slideProduct_menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
}
.slideProduct_menu button {
  width: 40px;
  height: 40px;
  border: 1px solid #cdc4db;
  border-radius: 50%;
  margin: 5px;
  background: transparent;
  color: #50318b;
  transition: 0.2s all;
  padding: 0;
}
.slideProduct_menu button:hover {
  background: #50318b;
  color: #ffffff;
}
.slideProduct_menu button:focus {
  outline: none;
}
.slideProduct_image:hover .slideProduct_menu {
  opacity: 1;
  visibility: visible;
}
.slideProduct_name {
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: 'poppinssemibold';
  font-size: 14px;
  line-height: 18px;
}
.slideProduct_box-price {
  display: flex;
  align-items: flex-end;
  font-family: 'poppinssemibold';
  flex-direction: column;
}
.slideProduct_box-price div {
  margin-left: 10px;
}
.slideProduct_price {
  color: #ff4858;
}
.slideProduct_discount {
  color: #c1c8ce;
  text-decoration: line-through;
}
.slideProduct_circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -5px;
  top: -5px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  background: #db308b;
  font-size: 10px;
  font-family: 'poppinssemibold';
  z-index: 2;
}
.slideProduct_box-name {
  width: 100%;
}
.slideProduct_box-price-mobile {
  display: none;
}
@media screen and (max-width: 765px) {
  .slick-slide .slideProduct_container {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .slideProduct_container {
    height: 300px;
  }
  .slideProduct_image {
    height: calc(100% - 120px);
  }
  .col-sm-6 .slideProduct_box-price {
    display: none;
  }
  .col-sm-6 .slideProduct_box-price-mobile {
    display: block;
    text-align: right;
  }
  .col-sm-6 .slideProduct_name {
    justify-content: flex-start;
  }
  .slideProduct_info {
    height: 83px;
    flex-direction: column;
  }
  .slideProduct_info .userImageRating_container {
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: row;
  }
  .slideProduct_info .userImageRating_container img {
    width: 40px;
    height: 40px;
    margin-bottom: 0;
    margin-right: 5px;
  }
  .slideProduct_name {
    height: 38px;
    overflow: hidden;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
  .slideProduct_discount {
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {
  .slideProduct_name {
    font-size: 13px;
    line-height: 15px;
  }
  .slideProduct_price {
    font-size: 14px;
  }
  .slideProduct_box-price div {
    margin-left: 0;
  }
  .slideProduct_info .userImageRating_container img {
    width: 35px;
    height: 35px;
  }
  .slideProduct_menu button {
    width: 35px;
    height: 35px;
  }
}
