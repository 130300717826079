.product_detail-prev-images {
  width: 100%;
  height: auto;
  min-height: 42px;
  position: relative;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 10px;
}
.product_detail-add {
  width: 40px !important;
  height: 40px !important;
  background: #50318b;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  transition: .2s all;
}
.product_detail-add:hover {
  background: #DB2F8B;
}
.product_detail-box-images {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}
.product_detail-box-image {
  width: 80px;
  height: 80px;
  padding: 5px;
  position: relative;
  margin: 5px;
  border: 2px solid #50318b;
}
.product_detail-box-image img {
  width: 100%;
  height: 100%;
}
.product_detail-remove {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  z-index: 1;
  background: red;
  color: #ffffff;
  cursor: pointer;
  transition: .2s all;
}
.product_detail-remove:hover {
  background: darkred;
}
.product_detail-form .form-control {
  margin-bottom: 10px;
}
.product_detail-input-complement {
  font-size: 12px;
}