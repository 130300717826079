.influencer-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.influencer-metric-row img {
  width: 40px;
  height: 38px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.influencer-metric-row .influencer__presentation {
  display: flex;
  align-items: center;
}

.networks img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
}

.influencer-metric-row {
  font-size: 16px;
}
.influencer-metric-row td {
  text-align: center;
  padding: 1rem 0;
  border-bottom: 1px solid rgb(187, 181, 181);
}
.influencer-metric-row .networks {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.network__circle {
  display: grid;
  place-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  transition: scale 0.2s ease-in-out;
}
a.network__circle:hover {
  scale: 1.3;
}

.network__circle:hover {
  text-decoration: none;
}

.network__circle.circle-instagram {
  background-color: #d1006d;
  color: var(--white);
}
.network__circle.circle-facebook {
  background-color: #3b5998;
  color: var(--white);
}
.network__circle.circle-youtube {
  background-color: #d42428;
  color: var(--white);
}
.network__circle.circle-tiktok {
  background-color: black;
  color: var(--white);
}
.network__circle.circle-twitter {
  background-color: #1da1f2;
  color: var(--white);
}
.network__circle.circle-linkedin {
  background-color: #0077b5;
  color: var(--white);
}
.influencer-metric-row .influencer__followers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.influencerRow__link__button {
  color: var(--white);
  text-decoration: none;
  text-align: center;
  display: block;
  padding: 0.5rem 1rem;
  background-color: var(--defaultPurple);
  border-radius: 10px;
}

.influencerRow__link__button:hover {
  text-decoration: none;
  background-color: var(--purpleHover);
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .influencer__presentation {
    display: flex;
    gap: 0;
  }
  .influencer__presentation img {
    margin-right: 0;
    width: 45px;
    height: 40px;
    object-fit: cover;
  }
  .influencer-metric-row td {
    font-size: 0.8rem;
  }
}
