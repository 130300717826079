@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?cx5mmz');
  src:  url('fonts/icomoon.eot?cx5mmz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?cx5mmz') format('truetype'),
    url('fonts/icomoon.woff?cx5mmz') format('woff'),
    url('fonts/icomoon.svg?cx5mmz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ad:before {
  content: "\e933";
}
.icon-import-products:before {
  content: "\e931";
}
.icon-referrals:before {
  content: "\e932";
}
.icon-company1:before {
  content: "\e900";
}
.icon-list-products:before {
  content: "\e901";
}
.icon-loup:before {
  content: "\e902";
}
.icon-service:before {
  content: "\e903";
}
.icon-add-car:before {
  content: "\e904";
}
.icon-address:before {
  content: "\e905";
}
.icon-bag:before {
  content: "\e906";
}
.icon-card:before {
  content: "\e907";
}
.icon-chat:before {
  content: "\e908";
}
.icon-chek:before {
  content: "\e909";
}
.icon-clock:before {
  content: "\e90a";
}
.icon-company:before {
  content: "\e90b";
}
.icon-dashboard:before {
  content: "\e90c";
}
.icon-dots:before {
  content: "\e90d";
}
.icon-down:before {
  content: "\e90e";
}
.icon-edit:before {
  content: "\e90f";
}
.icon-double-card:before {
  content: "\e910";
}
.icon-historial:before {
  content: "\e911";
}
.icon-menu:before {
  content: "\e912";
}
.icon-message:before {
  content: "\e913";
}
.icon-money:before {
  content: "\e914";
}
.icon-phone:before {
  content: "\e915";
}
.icon-products:before {
  content: "\e916";
}
.icon-profile:before {
  content: "\e917";
}
.icon-remove:before {
  content: "\e918";
}
.icon-screen:before {
  content: "\e919";
}
.icon-select-card:before {
  content: "\e91a";
}
.icon-send-message:before {
  content: "\e91b";
}
.icon-settings:before {
  content: "\e91c";
}
.icon-shopping-car:before {
  content: "\e91d";
}
.icon-up:before {
  content: "\e91e";
}
.icon-web:before {
  content: "\e91f";
}
.icon-agenda:before {
  content: "\e920";
}
.icon-company3:before {
  content: "\e921";
}
.icon-category:before {
  content: "\e922";
}
.icon-like:before {
  content: "\e923";
}
.icon-location:before {
  content: "\e924";
}
.icon-next:before {
  content: "\e925";
}
.icon-prev:before {
  content: "\e926";
}
.icon-score:before {
  content: "\e927";
}
.icon-to-cart:before {
  content: "\e928";
}
.icon-users:before {
  content: "\e929";
}
.icon-login:before {
  content: "\e92a";
}
.icon-profile1:before {
  content: "\e92b";
}
.icon-pencil:before {
  content: "\e92c";
}
.icon-plus:before {
  content: "\e92d";
}
.icon-close:before {
  content: "\e92e";
}
.icon-accept:before {
  content: "\e92f";
}
.icon-like-selected:before {
  content: "\e930";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
