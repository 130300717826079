.ModalSocial_container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--modal);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  z-index: 10;
}
