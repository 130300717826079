.galleryImages_container {
	margin-bottom: 20px;
}
.galleryImages_image-principal {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
	object-fit: cover;
}
.galleryImages_menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.galleryImages_menu button {
	width: 70px;
	height: 70px;
	margin: 5px;
	background: transparent;
	padding: 5px;
	border: 2px solid #E5E8EA;
}
.galleryImages_menu button.is_active {
	border: 2px solid #50318B !important;
}
.galleryImages_menu button:hover {
	border: 2px solid #BCBCBC;
}
.galleryImages_menu button:focus {
	outline: none;
}
.galleryImages_menu button img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}