.priceFilter_container {
  background: rgba(80, 49, 139, 0.1);
  padding: 15px;
  margin-bottom: 15px;
  font-family: 'poppinssemibold';
}
.priceFilter_title {
  font-size: 20px;
  margin-bottom: 5px;
  color: #db308b;
}
.priceFilter_sliderStyle {
  position: relative;
  width: 100%;
  height: 80px;
  border: 1px solid steelblue;
}
.priceFilter_sliderRail {
  position: absolute;
  width: 100%;
  height: 10px;
  margin-top: 35px;
  border-radius: 5px;
  background: #8b9cb6;
}
.priceFilter_apply {
  background: #50318b !important;
  color: #ffffff !important;
}
.priceFilter_input {
  appearance: none;
  font-size: 12px !important;
  text-align: right;
  -moz-appearance: textfield;
}
.priceFilter_input::-webkit-inner-spin-button,
.priceFilter_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
