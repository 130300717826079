.paymentModal_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  transition: 0.9s all ease-in;
  opacity: 0;
  visibility: hidden;
  color: #414141;
}
.paymentModal_overlay.active {
  visibility: visible;
  opacity: 1;
}
.paymentModal_content {
  width: 90%;
  max-width: 450px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  animation: modalOut 0.8s forwards;
}
.paymentModal_content.active {
  animation: modalIn 0.8s forwards;
}
@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}
.paymentModal_title {
  width: 200px;
  border-bottom: 1px solid #50318b;
  text-align: center;
  margin: 0 auto 10px;
  font-family: 'poppinsmedium';
}
.paymentModal_title span {
  margin-right: 10px;
  font-size: 20px;
}
.paymentModal_row-card input {
  margin-right: 10px;
}
.paymentModal_row-card span {
  font-size: 30px;
}
.paymentModal_content input,
.paymentModal_content select {
  margin-bottom: 10px;
}
.paymentModal_content label {
  color: #3c3c3b;
}
.paymentModal_btn-cancel {
  background: #db308b !important;
  color: #ffffff !important;
  margin: 5px;
}
.paymentModal_btn-save {
  background: #28cae4 !important;
  color: #ffffff !important;
  margin: 5px;
}
.paymentModal_menu {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #50318b;
  margin-bottom: 10px;
}
.paymentModal_menu button {
  border-top: 1px solid #50318b;
  border-left: 1px solid #50318b;
  border-right: 1px solid #50318b;
  border-bottom: 0;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
  background: #ffffff;
}
.paymentModal_menu button.active {
  background: #50318b;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .paymentModal_overlay {
    align-items: flex-start;
    padding-top: 20px;
    overflow: auto;
  }
  .paymentModal_content {
    margin-bottom: 20px;
  }
}
