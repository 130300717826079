.productsView_title-category {
  font-family: 'poppinssemibold';
  font-size: 16px;
  margin-bottom: 20px;
  color: #50318b;
}
.productsView_title-category a {
  color: #db308b;
}
.productsView_title-category a:hover {
  color: #db308b;
}
