/* a */
.influencerFilter__container {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 13rem;
  margin-top: 1rem;
}
/* Demographic forms */
.demographic__form {
  margin-top: 0.5rem;
}

.form__select {
  appearance: none;
  border-radius: 10px;
  display: block;
  width: 100%;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}
.form__select:focus {
  outline: none;
  box-shadow: 0 0 10px 0 #927ebd80;
}
.form__select[disabled] {
  background-color: #49505746;
}
.form__select ::-ms-expand {
  display: none;
}
.form__select option {
  background-color: #ffffff;
  color: #000000;
  outline: none;
  border: none;
}
.form__select option :hover {
  background-color: var(--mediumPurple);
  color: #ffffff;
}
.form__select option:checked {
  background-color: var(--mediumPurple);
  color: #fff;
}

/* Social media form */
.form__influencerSocial {
  margin-top: 1rem;
}

/* keyworkds */
.keywords__title {
  color: var(--defaultPurple);
  font-weight: bold;
  font-size: 0.9rem;
}

.pill {
  background-color: var(--defaultPink);
  border-radius: 25px;
  color: var(--white);
  font-size: 14px;
  margin: 0 3px 3px 0;
  padding: 4px 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.pill:hover {
  background-color: var(--lightPurpleHover);
}

.pill.selected {
  background-color: var(--defaultPurple);
  color: #fff;
}
/* button */
.filter__btn {
  background-color: var(--defaultPurple);
  border: 2px solid var(--defaultPurple);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  color: var(--white);
  margin-top: 1rem;
  transition: 0.2s all;
}
.filter__btn:hover {
  background-color: var(--defaultPink);
  border: 2px solid var(--defaultPurple);
}

.influencerFilter__pills {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.influencerFilter__pills .pill {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.influencerFilter__pills .pill button {
  background-color: transparent;
  color: var(--white);
  border: 0;
  width: 18px;
  font-size: 16px;
}
/* medias */
@media screen and (max-width: 767px) {
  .influencerFilter__container {
    max-width: 100%;
  }
}
