.productView_container {
  margin-top: 20px;
  font-family: 'poppinsregular';
}
.productView_title {
  font-family: 'poppinssemibold';
  font-size: 20px;
  color: #50318b;
  margin-bottom: 30px;
}
.productView_title a {
  color: #50318b;
}
.productView_marketplace {
  color: #2aa9db !important;
}
.productView_detail {
  margin-bottom: 20px;
}
.productView_related {
  width: calc(100% - 30px);
  height: 5px;
  margin: 20px auto;
  background: rgba(229, 232, 234, 0.5);
}
.productView_subtitle {
  text-align: center;
  font-family: 'poppinssemibold';
  color: #50318b;
  font-size: 25px;
  margin-bottom: 10px;
}
.productView_no-image {
  width: 100%;
  height: auto;
}
.productView_separate {
  padding: 5px 0 20px;
  border-bottom: 3px solid #f6f7f8;
}
.productView_separate button {
  margin-right: 10px;
}
.productView_social-title {
  color: #50318b;
  font-family: 'poppinssemibold';
  margin-top: 10px;
}
.productView_title-product {
  font-family: 'poppinssemibold';
  color: #50318b;
  font-size: 20px;
}
.productView_rating {
  margin-right: 10px;
}
.productView_rating span {
  margin-right: 2px;
}
.productView_reviews {
  font-family: 'poppinssemibold';
  color: #c1c8ce;
  margin-right: 10px;
}
.productView_to-reviews {
  font-family: 'poppinssemibold';
  border: 0;
  background: transparent;
  color: #2aa9db;
}
button:focus {
  outline: none !important;
}
.productView_box-price {
  font-family: 'poppinssemibold';
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.productView_price {
  color: #ff4858;
  margin-right: 20px;
}
.productView_discount {
  color: #c1c8ce;
  text-decoration: line-through;
}
.productView_availability {
  font-family: 'poppinssemibold';
  width: 120px;
}
.productView_title-short-description {
  font-family: 'poppinssemibold';
  color: #50318b;
  font-size: 16px;
  margin-bottom: 10px;
}
.productView_short-description {
  font-size: 14px;
  text-align: justify;
  color: #262626;
}
.productView_btn-marketplace {
  background: #50318b !important;
  color: #ffffff !important;
  margin: 5px 10px 0 0;
}
.productView_btn-marketplace span {
  color: #ffffff;
}
.productView_btn-cart span,
.productView_btn-sharer span {
  margin-right: 10px;
  color: #ffffff !important;
}
.productView_btn-sharer {
  color: #ffffff !important;
  margin: 5px 10px 0 0;
}
.productView_facebook {
  background: #385c8e !important;
}
.productView_twitter {
  background: #03a9f4 !important;
}
.productView_box-description {
  background: rgba(80, 49, 139, 0.05);
}
.productView_menu-description {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #e5e8ea;
  padding: 0 10px;
}
.productView_menu-description button {
  font-family: 'poppinssemibold';
  border: 0;
  border-bottom: 2px solid #e5e8ea;
  background: transparent;
  width: 120px;
  font-size: 14px;
  line-height: 16px;
  color: #50318b;
}
.productView_menu-description button.active {
  border-bottom: 2px solid #50318b;
}
.productView_content-description {
  width: 100%;
  height: 250px;
  padding: 20px;
}
.productView_view-description {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.productView_title-seller {
  font-family: 'poppinssemibold';
  color: #50318b;
  text-align: center;
  margin-bottom: 10px;
}
.productView_view-reviews {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
.productsView_menu {
  display: flex;
  height: auto;
}
.productsView_menu a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #db308b;
  color: #ffffff;
  font-size: 16px;
  padding: 0 10px;
  height: 40px;
  transition: 0.2s all;
}
.productsView_menu a:hover {
  background-color: #50318b;
  color: #ffffff;
  text-decoration: none;
}
.productsView_menu a i {
  margin-right: 5px;
  font-size: 20px;
}
.productsView_menu a:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.productsView_menu a:last-child {
  border-radius: 0 5px 5px 0;
}
@media screen and (max-width: 430px) {
  .productsView_menu a {
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen and (max-width: 767px) {
  .productView_box-description {
    margin-bottom: 20px;
  }
}
