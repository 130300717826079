.form-porposal-container {
  padding: 1rem 8rem;
}

.form-porposal-container .checkbox__image {
  gap: 1rem;
}

@media only screen and (max-width: 730px) {
  .form-porposal-container {
    padding: 0;
  }
}
