.historialView_filter {
  padding: 15px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 15px 0 25px;
}
.historialView_filter label {
  color: #797474;
  font-size: 12px;
}
.historialView-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  border: 0;
  background: transparent;
  transition: 0.2s all;
}
.historialView-button:hover {
  color: #50318b;
}
.historialView-button span {
  font-size: 30px;
}
.historialView-content {
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 25px;
}
.historial_number {
  color: #000000;
  font-size: 16px;
}
.historial-definitions {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #797474;
}
.historial-label {
  color: #50318b;
  font-family: 'poppinsbold';
}
.historial-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.historial-menu button {
  margin: 5px;
}
