.cropper_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 25;
  background: rgba(0, 0, 0, 0.4);
  transition: 1s all;
  opacity: 0;
  visibility: hidden;
}
.cropper_container.visible {
  opacity: 1;
  visibility: visible;
}
.cropper_box {
  width: 100%;
  max-width: 420px;
  height: 400px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  animation: modalOut 0.8s forwards;
}
.cropper_box.visible {
  animation: modalIn 0.8s forwards;
}
.cropper_menu {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}
