.paymentMethodItem_row {
	color: #797474;
}
.paymentMethodItem_btn {
	border: 0;
	background: transparent;
	font-size: 25px;
	color: #000000;
}
.paymentMethodItem_btn:hover {
	color: #50318B;
}
.paymentMethodItem_btn:focus {
	outline: none;
}