/* chat */
.inbox__contact {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 5px;
  border-bottom: 1px solid rgba(224, 219, 219, 0.753);
  align-items: stretch;
  color: black;
  transition: border-bottom 0.4s ease-in-out;
}
.inbox__contact.active {
  background: #dfe7ef;
}
.inbox__contact:hover {
  text-decoration: none;
  color: var(--defaultPurple);
  border-bottom: 1.5px solid var(--defaultPurple);
}
.contact__info {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.inbox__contact img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.data__contact {
  display: flex;
  flex-direction: column;
}
.name__contact {
  font-size: 16px;
  text-transform: capitalize;
  font-family: 'poppinsbold';
}
.type__msg {
  font-size: 0.8rem;
  color: rgb(187, 177, 177);
}
.action__contact {
  display: flex;
  gap: 1rem;
  align-items: center;
  min-height: 100%;
}
.action__contact button {
  background-color: transparent;
  border: none;
  color: var(--defaultPurple);
}
.pending__dot {
  background-color: var(--defaultPink);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.content__message {
  background-color: white;
}
.answers {
  margin: 1rem 0;
  margin-top: 2rem;
}
.answers__buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}
.answers__buttons button {
  width: 10rem;
  height: 2.3rem;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: var(--white);
}
.answers__buttons button:nth-child(1) {
  background-color: var(--defaultPink);
}
.answers__buttons button:nth-child(2) {
  background-color: var(--defaultPurple);
}
.answers__buttons button:nth-child(3) {
  background-color: var(--defaultBlue);
}
.response .campaign__input {
  height: 10rem;
}
.response {
  display: flex;
  flex-direction: column;
}
.response button {
  align-self: flex-end;
  border: none;
  border-radius: 5px;
  width: 10rem;
  height: 2.3rem;
  background-color: var(--defaultBlue);
  color: var(--white);
  text-transform: uppercase;
}
