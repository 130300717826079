.profileView_left {
  font-family: 'poppinsregular';
}
.profileView_center {
  font-family: 'poppinsregular';
  text-align: center;
}
.profileView_img-profile {
  width: 200px;
  height: 200px;
  border: 1px solid #dfe0e4;
  margin: 20px auto;
  border-radius: 5px;
  padding: 10px;
}
.profileView_icon {
  width: 30px;
  color: #db308b;
}
.profileView_name {
  font-size: 16px;
}
.profileView_category {
  font-family: 'poppinsmedium';
  color: #50318b;
  margin-bottom: 5px;
}
.profileView_row {
  margin-bottom: 10px;
}
.profileView_form {
  margin-bottom: 20px;
  padding-bottom: 15px;
  /* border-bottom: 1px solid #50318b; */
}
.profileView_btn-save {
  color: #ffffff !important;
  background: #db308b !important;
}
.profileView_textarea {
  height: 130px !important;
  margin-bottom: 10px;
}
.profileView_cover-page {
  width: 100%;
  min-height: 260px;
}
.profileView_header {
  width: 100%;
  padding: 15px;
  background: #50318b;
  margin-bottom: 20px;
}
.profileView_box-image {
  width: 100%;
  height: 100%;
  position: relative;
}
.profileView_box-image img {
  width: 100%;
  height: auto;
}
.profileView_remove {
  width: 40px;
  height: 40px;
  background: #db308b;
  color: #ffffff;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
}

.influencer__addSocialNetwork {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .profileView_cover-page {
    min-height: 100%;
  }
}
