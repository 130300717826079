.homeMenu_container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #50318b;
  margin-bottom: 15px;
}
.homeMenu_link,
.homeMenu_container button {
  height: 60px;
  color: #ffffff;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #50318b;
  transition: 0.2s all;
  border: 0;
}
.homeMenu_link:hover,
.homeMenu_container button:hover {
  color: #ffffff;
  text-decoration: #ffffff;
  background: #db308b;
}
.homeMenu_link span,
.homeMenu_container button span {
  font-size: 28px;
  margin-right: 10px;
}
.homeMenu_container button:focus {
  outline: none;
}
.homeMenu_active {
  color: #ffffff;
  text-decoration: #ffffff;
  background: #db308b;
}
@media screen and (max-width: 1025px) {
  .homeMenu_container {
    position: fixed;
    left: 0;
    bottom: -1px;
    margin: 0;
    z-index: 3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height: 80px;
  }
  .homeMenu_container a,
  .homeMenu_container button {
    width: 25%;
  }
  .homeMenu_link,
  .homeMenu_container button {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
  }
  .homeMenu_link span,
  .homeMenu_container button span {
    font-size: 22px;
  }
}
@media screen and (max-width: 813px) {
  .homeMenu_container a,
  .homeMenu_container button {
    flex-direction: column;
    font-size: 10px;
    text-align: center;
    justify-content: flex-start;
  }
  .homeMenu_container a span,
  .homeMenu_container button span {
    margin: 0;
  }
}
