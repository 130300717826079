.selectUser_row {
	width: 100%;
	background: #ffffff;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 1px solid #50318B;
	transition: .2s all;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 6px 10px;
}
.selectUser_row:hover {
	color: #28CAE4;
	border-bottom: 1px solid #28CAE4;
}
.selectUser_row:focus {
	outline: none;
}
.selectUser_image {
	width: 30px;
	height: 30px;
	margin-right: 5px;
	border-radius: 50%;
}