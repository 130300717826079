.SelectOption_content {
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: var(--white);
  border-radius: 20px;
}
.SelectOption_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.SelectOption_header h2 {
  font-size: 24px;
  padding-right: 20px;
}
.SelectOption_body {
  padding-top: 10px;
}
.SelectOption_button {
  width: 100%;
  height: 40px;
  border: 2px solid var(--purple) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: var(--white);
  transition: 0.2s all;
}
.SelectOption_button:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.65);
  transform: translateY(-3px);
}
.SelectOption_button.selected {
  background-color: var(--purple);
  color: var(--white);
  font-family: 'poppinsbold';
}
.SelectOption_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SelectOption_footer button {
  width: calc(100% / 2 - 5px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0;
  transition: 0.2s all;
}
.SelectOption_cancel {
  background-color: var(--defaultPink);
  color: var(--white);
}
.SelectOption_continue {
  background-color: var(--defaultBlue);
  font-family: 'poppinsbold';
}
.SelectOption_cancel:hover {
  background-color: var(--pinkHover);
}
.SelectOption_continue:hover {
  background-color: var(--blueHover);
}
