.SliderCategories_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.sliderCategories_mobile {
  display: none;
}
@media screen and (max-width: 1024px) {
  .sliderCategories_desktop {
    display: none;
  }
  .sliderCategories_mobile {
    width: 100%;
    overflow-y: auto;
    display: block;
  }
}