.inboxChats_container {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
.inboxChats_container-title {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #dadfe2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inboxChats_title {
  font-family: 'poppinsmedium';
  font-size: 16px;
}
.inboxChats_content {
  padding: 20px;
}
.inboxChats_menu {
  display: flex;
}
.inboxChats_menu button {
  margin-left: 10px;
  background: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  transition: 0.2s all;
}
.inboxChats_menu button:focus {
  outline: none;
}
.inboxChats_no-read {
  color: #28cae4;
  border-bottom: 3px solid #28cae4;
  font-size: 14px;
  display: block;
  padding: 0;
}
.inboxChats_no-read:hover {
  color: #28cae4;
  text-decoration: none;
  opacity: 0.75;
}
.inboxChats_all {
  color: #50318b;
  border-bottom: 3px solid #50318b;
  font-size: 14px;
  display: block;
  padding: 0;
}
.inboxChats_all:hover {
  color: #50318b;
  text-decoration: none;
  opacity: 0.7;
}
