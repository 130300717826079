.pagination_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
}
.pagination_container button {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eff0f4;
  color: #50318b;
  transition: 0.2s all;
  font-size: 12px;
}
.pagination_container button:hover {
  background: #eff0f4;
}
.pagination_container button:focus {
  outline: none;
}
.pagination_container button.active {
  background: #50318b;
  border: 1px solid #50318b;
  color: #ffffff;
}
