.menuCategory_container {
  background: rgba(80, 49, 139, 0.1);
  padding: 15px;
  font-family: 'poppinssemibold';
  margin-bottom: 15px;
}
.menuCategory_title {
  color: #db308b;
  font-size: 20px;
  margin-bottom: 10px;
}
.menuCategory_item {
  color: #50318b;
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  font-size: 14px;
  transition: 0.2s all;
}
.menuCategory_item:hover {
  color: #2aa9db;
  text-decoration: none;
}
.menuCategory_name {
  width: calc(100% - 30px);
}
.menuCategory_active {
  color: #db308b;
}
.menuCategory_container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menuCategory_button {
  width: 35px;
  height: 35px;
  display: none;
}
@media screen and (max-width: 1001px) {
  .menuCategory_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #50318b;
    color: #ffffff;
    border-radius: 50%;
    border: 0;
    transition: 0.2s all;
  }
  .menuCategory_button.visible {
    transform: rotate(180deg);
  }
  .menuCategory_content {
    display: none;
  }
  .menuCategory_content.visible {
    display: block;
  }
}
