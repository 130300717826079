.header_container {
  width: 100%;
  height: 60px;
  background: #50318b;
  transition: 0.2s all;
}
.header_logo {
  background: #ffffff;
}
.header_logo,
.header_logo a {
  width: 160px;
  height: 60px;
}
.header_logo img {
  height: 40px;
}
.header_menu {
  width: calc(100% - 160px);
  height: 60px;
}
.header_box-logo {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 0 20px 0 10px;
}
.header_box-logo img {
  width: 120px !important;
  height: auto !important;
}
.header_box-logo button {
  border: 0;
  background: transparent;
  font-size: 22px;
  color: #4f308b;
}
.header_box-logo button:focus {
  outline: none;
}
@media screen and (max-width: 1025px) {
  .header_container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .header_container.header_fixed {
    display: none !important;
  }
}
@media screen and (max-width: 680px) {
  .header_box-logo {
    width: 150px;
  }
  .header_box-logo button {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_box-logo img {
    width: 80px !important;
  }
  .header_container.fixed > .header_box-logo img {
    display: none;
  }
  .header_container.fixed > .header_box-logo {
    width: 60px;
  }
}
@media screen and (max-width: 330px) {
  .header_box-logo,
  .header_logo {
    position: absolute;
    z-index: 1;
  }
  .header_container.fixed > .header_box-logo img {
    display: block;
  }
  .header_container.fixed > .header_box-logo {
    width: 150px;
  }
}
