.chatItem_row {
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #dadfe2;
  display: flex;
  align-items: center;
  transition: 0.2s all;
  padding: 5px;
}
.chatItem_row.active {
  background: #dfe7ef;
}
.chatItem_row:hover {
  border-bottom: 1px solid #50318b;
}
.chatItem_go {
  display: flex;
  align-items: center;
  color: #000000;
  transition: 0.2s all;
}
.chatItem_go:hover {
  text-decoration: none;
  color: #50318b;
}
.chatItem_goAll {
  width: 100%;
  height: 100%;
}
.chatItem_goAction {
  width: calc(100% - 35px);
  height: 100%;
}
.chatItem_image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.chatItem_description {
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.chatItem_name {
  font-family: 'poppinsbold';
}
.chatItem_message {
  font-size: 13px;
}
.chatItem_box-actions {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.chatItem_notification {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #db308b;
  margin-right: 10px;
}
.chatItem_view-actions {
  border: 0;
  background: transparent;
  padding: 0;
}
