.demographic__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.demographic__container .col-city,
.demographic__container .col-country {
  width: 100%;
}
.demographic__container .col-city {
  margin-bottom: 10px;
}
.demographic__container .add__ubication {
  margin-top: 0;
}
.demographic__ubication {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demographic__ubication .col-city {
  width: calc(100% / 2 - 45px);
}
.demographic__ubication .col-country {
  width: calc(100% - 35px);
}
.form__select {
  appearance: none;
  border-radius: 10px;
  display: block;
  width: 100%;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}
.form__select:focus {
  outline: none;
  box-shadow: 0 0 10px 0 #927ebd80;
}
.form__select[disabled] {
  background-color: #49505746;
}
.form__select ::-ms-expand {
  display: none;
}
.form__select option {
  background-color: #ffffff;
  color: #000000;
  outline: none;
  border: none;
}
.form__select option :hover {
  background-color: var(--mediumPurple);
  color: #ffffff;
}
.form__select option:checked {
  background-color: var(--mediumPurple);
  color: #fff;
}
