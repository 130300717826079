.viewModal_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background: rgba(0, 0, 0, 0.4);
  transition: 0.5s all;
  opacity: 0;
  visibility: hidden;
}
.viewModal_container.visible {
  opacity: 1;
  visibility: visible;
}
.viewModal_box {
  width: 100%;
  max-width: 420px;
  height: auto;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(94, 49, 49, 0.3);
  animation: modalOut 0.8s forwards;
}
.viewModal_box.visible {
  animation: modalIn 0.8s forwards;
}
.viewModal_title {
  font-family: 'poppinsbold';
  text-align: center;
  font-size: 20px;
  color: #db2f8b;
  margin-bottom: 10px;
}
.viewModal_textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.viewModal_menu {
  display: flex;
  justify-content: flex-end;
}
.viewModal_menu button {
  margin: 0 0 5px 10px;
}
.viewModal_box.bottom {
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 25px;
  left: 0;
}
.viewModal_box.top {
  position: absolute;
  margin: auto;
  right: 0;
  top: 25px;
  left: 0;
}
.viewModal_img {
  width: 200px;
  margin: 0 auto 10px;
  display: block;
}
.viewModal_icon {
  width: 65px;
  height: 65px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  margin: 0 auto 10px;
}
.viewModal_arrow-bottom {
  width: 0;
  height: 0;
  margin: 0 auto;
  position: absolute;
  border-top: 20px solid #ffffff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  right: 0;
  bottom: -19px;
  left: 0;
}
.viewModal_arrow-top {
  width: 0;
  height: 0;
  margin: 0 auto;
  position: absolute;
  border-bottom: 20px solid #ffffff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  right: 8px;
  top: -19px;
}
@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}
@media screen and (max-width: 500px) {
  .viewModal_box {
    width: 90%;
  }
}
