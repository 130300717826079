.listMessage_container {
  width: 100%;
  height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid var(--white);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px;
}
.listMessage_item {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}
.listMessage_item.current {
  justify-content: flex-end;
}
.listMessage_item:nth-child(1) {
  color: #000000;
}
.listMessage_box-user {
  width: 85%;
  padding: 15px;
  border-radius: 20px;
  color: var(--white);
}
.listMessage_box-user.current {
  background-color: var(--defaultPurple);
}
.listMessage_box-user.other {
  background-color: var(--defaultBlue);
}
.listMessage_send-by {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listMessage_user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.listMessage_user img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid var(--white);
}
.listMessage_date {
  text-align: right;
  font-size: 12px;
}
.listMessage_message_content {
  word-break: break-all;
}
