.homeSlider_container {
  width: 100%;
  position: relative;
  /* background: rgba(255,72,78,1);
	background: -moz-linear-gradient(45deg, rgba(255,72,78,1) 0%, rgba(142,101,255,1) 100%);
	background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255,72,78,1)), color-stop(100%, rgba(142,101,255,1)));
	background: -webkit-linear-gradient(45deg, rgba(255,72,78,1) 0%, rgba(142,101,255,1) 100%);
	background: -o-linear-gradient(45deg, rgba(255,72,78,1) 0%, rgba(142,101,255,1) 100%);
	background: -ms-linear-gradient(45deg, rgba(255,72,78,1) 0%, rgba(142,101,255,1) 100%);
	background: linear-gradient(45deg, rgba(255,72,78,1) 0%, rgba(142,101,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff484e', endColorstr='#8e65ff', GradientType=1 ); */
}
.homeSlider_arrow {
  width: 30px;
  height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: 2;
  background: transparent;
  color: #ffffff;
  font-size: 30px;
  justify-content: center;
  border: 0;
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeSlider_arrow:hover {
  color: #f8a826;
}
.homeSlider_arrow:focus {
  outline: none;
}
.homeSlider_arrow-left {
  left: 5px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.homeSlider_arrow-right {
  right: 5px;
  text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.5);
}
.homeSlider_container img {
  width: 100%;
  height: auto;
}
.homeSlicer_img-mobile {
  display: none !important;
}
@media screen and (max-width: 1025px) {
  .homeSlider_container {
    margin-top: 60px;
    margin-bottom: -60px;
  }
}
@media screen and (max-width: 680px) {
  .homeSlider_arrow {
    font-size: 16px;
  }
  .homeSlicer_img-desktop {
    display: none !important;
  }
  .homeSlicer_img-mobile {
    width: calc(100% - 20px) !important;
    border-radius: 20px;
    margin: 10px auto !important;
    display: block !important;
  }
}
