.saleList_container {
	width: 100%;
}
.saleList_theader {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: row;
	padding: 5px 0;
	border-bottom: 1px solid #50318B;
	font-size: 16px;
}
.saleList_width5 {
	width: 20%;
}
.saleList_width4 {
	width: 24%;
}