.campaign__table {
  width: 100%;
}
.campaignTable__title {
  text-align: center;
  min-width: 5rem;
  padding: 0.3rem;
  color: var(--defaultPurple);
}
.campaignTable__head {
  border-bottom: 2px solid var(--defaultPurple);
}
.campaign__table__container {
  width: 100%;
  overflow: auto;
}
