.userImageRating_container {
	width: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.userImageRating_container img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
	background: #ffffff;
	box-shadow: 0 5px 10px rgba(0,0,0,.1);
	margin-bottom: 10px;
}
.userImageRating_rating {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 9px;
}
.userImageRating_rating span {
	margin: 0 2px;
	color: #C1C8CE;
}
.userImageRating_rating span.active {
	color: #FFC600;
}