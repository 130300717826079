.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 0.5rem;
  justify-items: center;
  margin: 1rem 0;
}

@media screen and (min-width: 992px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

.gallery__item {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 120px;
}

.gallery__item img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 2px;
  /* scale: 0.9; */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.modal.open {
  display: flex;
  opacity: 1;
  transform: scale(1);
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  animation-name: pop-in;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
