.menuHeader_width-manager {
	width: calc(100% - 230px);
}
.menuHeader_width-marketplace {
	width: calc(100% - 160px);
}
.menuHeader_container-menu {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.menuHeader_box-menu {
	width: calc(100% - 60px);
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.menuHeader_info-user a, .menuHeader_info-user button{
	color: #ffffff;
	padding: 0 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: .2s all;
	border: 0;
	background: transparent;
}
.menuHeader_info-user a:hover, .menuHeader_info-user button:hover {
	color: #28CAE4;
	text-decoration: none;
}
.menuHeader_info-user button:focus {
	outline: none;
}
.menuHeader_info-user .icon {
	font-size: 25px;
	margin-right: 10px;
	color: #ffffff;
}
.menuHeader_info-user {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: #ffffff;
	position: relative;
}
.menuHeader_container-image {
	width: 35px;
	height: 35px;
	position: relative;
	margin-right: 10px;
}
.menuHeader_box-image-header {
	width: 100%;
	height: 100%;
	background: #ffffff;
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid #ffffff;
}
.menuHeader_box-image-header img {
	width: 35px;
	height: 35px;
}
.menuHeader_to-manager {
	width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	transition: .2s all;
	padding: 0 10px;
}
.menuHeader_to-manager span {
	transition: .2s all;
}
.menuHeader_to-manager:hover {
	color: #00A8EB;
	text-decoration: none;
}
.menuHeader_to-manager:hover span {
	color: #00A8EB;
}
.menuHeader_go-cart {
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	position: relative;
	transition: .2s all;
	border: 0;
	border-left: 1px solid rgba(255,255,255,.2) !important;
	border-right: 1px solid rgba(255,255,255,.2) !important;
	background: #50308B;
}
.menuHeader_go-cart span {
	margin: 0;
	font-size: 20px;
}
.menuHeader_go-cart:hover {
	background: #00A8EB;
	color: #ffffff;
	text-decoration: none;
}
.menuHeader_tooltip-cart {
	width: 18px;
	height: 18px;
	background: #DB308B;
	position: absolute;
	border-radius: 50%;
	top: 10px;
	right: 10px;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #ffffff;
}
.menuHeader_name {
	font-size: 16px !important;
	margin: 0 !important;
}
.btn-menu {
	display: none;
}
@media screen and (max-width: 813px) {
	.menuHeader_info-user a {
		display: flex;
		flex-direction: column;
		font-size: 10px;
		text-align: center;
		align-items: center;
		justify-content: center;
		width: 50px;
	}
	.name-menu {
		display: none;
	}
	.menuHeader_info-user a span {
		margin: 0;
	}
	.menuHeader_to-manager {
		width: 60px;
	}
	.menuHeader_name {
		display: none;
	}
	.menuHeader_container-image {
		margin: 0;
	}
}
@media screen and (max-width: 680px) {
	.btn-profile {
		display: none !important;
	}
}
@media screen and (max-width: 330px) {
	.menuHeader_container-menu {
		width: 100%;
		position: relative;
		border-bottom: 1px solid rgba(255,255,255,.2);
	}
	.menuHeader_info-user {
		width: 100%;
		height: 0;
		position: absolute;
		top: 60px;
		z-index: 3;
		background: #50308B;
		flex-direction: column;
		overflow: auto;
		box-shadow: 0 5px 5px rgba(0,0,0,.5);
		transition: .2s all;
	}
	.menuHeader_info-user.visible {
		height: auto;
	}
	.menuHeader_info-user a {
		height: 45px;
		width: 100%;
		flex-direction: row;
		border-bottom: 1px solid rgba(255,255,255,.2);
		font-size: 13px;
	}
	.menuHeader_info-user a span {
		margin-right: 10px;
	}
	.menuHeader_go-cart {
		border-left: 0 !important;
		border-right: 0 !important;
	}
	.btn-menu {
		display: block;
		font-size: 22px;
		width: 60px;
		height: 100%;
		background: none;
		border: 0;
		color: #ffffff;
	}
	.name-menu {
		display: block;
	}
}