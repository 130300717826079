.divider {
  border-top: 2px solid rgba(194, 189, 189, 0.753);
  width: 85%;
  margin: 2rem auto;
}

.performance h2,
.influencer__metrics h3 {
  font-size: 1.5rem;
  color: var(--defaultPurple);
}
.analytics__content {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #d1cdcd;
  margin: 2rem 0;
  justify-content: space-evenly;
}
.analytics__content .analityc__data__title {
  gap: 1rem;
  justify-content: center;
}
/* Estilos para dispositivos móviles */
@media only screen and (max-width: 990px) {
  .analytics__content {
    flex-direction: column;
    margin: 1rem 0;
    gap: 1rem;
    border: none;
  }
  .analytics__content .analityc__data__container {
    border-bottom: 2px solid rgba(211, 207, 207, 0.781);
  }
}
@media only screen and (max-width: 767px) {
  .performance h2,
  .influencer__metrics h3 {
    font-size: 1.2rem;
  }

  .analytics__content {
    flex-direction: column;
    margin: 1rem 0;
    gap: 1rem;
    border: none;
  }

  .separator__vertical {
    display: none;
  }

  .audience__container {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .recent__posts__title h3 {
    font-size: 1.2rem;
    margin: 1rem 0;
  }

  .influencer__metrics table {
    font-size: 0.8rem;
  }
}
/* Card */
.container__card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 2rem;
  overflow-x: auto;
}
