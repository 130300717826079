.marketplaceLayout_container {
	background: #f9f7fb;
	min-height: 100%;
	display: flex;
	flex-direction: row;
}
.marketplaceLayout_content {
	width: 100%;
	min-height: 100%;
}
@media screen and (max-width: 1025px) {
	.marketplaceLayout_container .container {
		margin-top: 65px;
	}
}