.questions_container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-family: 'poppinsregular';
}
.questions_content {
  width: 90%;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.questions_button-arrow {
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: var(--defaultPurple);
  color: var(--white);
  border: 0;
  transition: 0.2s all;
  position: relative;
  z-index: 5;
}
.questions_button-arrow:hover {
  background-color: var(--purpleHover);
}
.questions_questions {
  width: calc(100% - 100px);
  height: 100%;
}
.questions_question {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: -100%;
  transition: 0.75s all;
}
.questions_question.visible {
  right: 0;
}
.questions_question.hide {
  right: 100%;
}
.questions_question-content {
  width: calc(100% - 100px);
  max-width: 600px;
}
.questions_children {
  padding: 20px 0 10px;
}
.questions_question-text {
  font-size: 26px;
}
.questions_note {
  font-size: 16px;
  color: var(--gray);
}
.questions_button {
  padding: 8px 20px;
  border-radius: 5px;
  background-color: var(--defaultPurple);
  transition: 0.2s all;
  color: var(--white);
  border: 0;
  font-family: 'poppinsbold';
  font-size: 18px;
}
.questions_button:hover {
  background-color: var(--purpleHover);
}
.questions_intro {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.questions_intro img {
  width: 100%;
  max-width: 350px;
  margin-right: 30px;
}
.questions_intro-content {
  width: calc(100% - 380px);
}
.questions_intro-title {
  font-size: 22px;
  margin-bottom: 10px;
}
.questions_intro-note {
  font-size: 14px;
  margin-top: 10px;
  color: var(--gray);
}
.questions_select-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.questions_select-options button {
  width: 100%;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  border: 2px solid var(--mediumPurple);
  background-color: var(--lightPurple);
  transition: 0.2s all;
  color: var(--purpleHover);
  font-size: 18px;
  border-radius: 5px;
}
.questions_select-options button i {
  margin-right: -5px;
}
.questions_select-options button:hover {
  background-color: var(--lightPurpleHover);
}
.questions_select-options button.active {
  border: 2px solid var(--purpleHover);
}
.questions_confirm {
  max-width: 130px;
}
.questions_input,
.questions_select,
.questions_textarea {
  border: 0;
  width: 100%;
  font-size: 18px;
  padding: 0;
  padding-bottom: 2px;
  border-bottom: 2px solid var(--lightPurple);
  color: var(--purpleHover);
  transition: 0.2s all;
  margin: 5px 0;
}
.questions_textarea {
  height: 100px;
}
.questions_input:focus,
.questions_select:focus,
.questions_textarea:focus {
  outline: none;
  border-bottom: 2px solid var(--mediumPurple);
}
.questions_option {
  max-width: 350px;
}
.questions_content-companies {
  width: 100%;
  height: 280px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--lightPurple);
}
.questions_companies {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.questions_companies .settingsView_company {
  padding: 15px;
}
body.swal2-height-auto {
  height: 100% !important;
}
.questions_logo {
  width: 100px;
  height: auto;
  margin: 15px;
}
.questions_conten-line {
  width: 100%;
  height: 5px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--lightPurple);
}
.questions_line {
  height: 5px;
  background-color: var(--mediumPurple);
  transition: 0.2s all;
}
.questions_back-button {
  display: flex;
  background-color: var(--lightPurple);
  align-items: center;
  padding: 0;
  padding-right: 10px;
  border-radius: 5px;
  border: 0;
  margin-bottom: 5px;
  transition: 0.2s all;
}
.questions_back-button:hover {
  background-color: var(--purpleHover);
  color: var(--white);
}
.questions_back-button div {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--white);
  margin-right: 10px;
}
.questions_children {
  width: 100%;
  max-width: 600px;
}
@media screen and (max-width: 631px) {
  .questions_intro {
    flex-direction: column;
  }
  .questions_intro img {
    margin-right: initial;
  }
  .questions_intro-content {
    width: 100%;
  }
  .questions_logo {
    width: 80px;
    height: auto;
    margin: 0;
    position: fixed;
    top: 15px;
    left: 20px;
  }
  .questions_question-text {
    font-size: 20px;
  }
  .questions_button-arrow {
    width: 30px;
    height: 30px;
  }
}
