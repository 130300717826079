.slideCompany_container {
  width: 100%;
  max-width: 250px;
  height: auto;
  display: block;
  margin: 10px auto 25px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 23px 23px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.2s all;
  color: #595959;
  font-family: 'poppinsregular';
  position: relative;
}
.slideCompany_container:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  color: #50318b;
  text-decoration: none;
}
.slideCompany_image {
  width: 100%;
  height: auto;
}
.slideCompany_name {
  width: 100%;
  height: 35px;
  margin: auto;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}
.slideCompany_rating {
  width: 0;
  height: 15px;
  position: absolute;
  top: 23px;
  right: 17px;
  color: #c1c8ce;
  display: flex;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
.slideCompany_rating span {
  margin-bottom: 3px;
}
.slideCompany_rating span.active {
  color: #ffc600;
}
.slideCompany_like {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid var(--defaultPurple);
  color: var(--defaultPurple);
  padding: 0;
  top: 5px;
  left: 5px;
  font-size: 16px;
  padding-top: 5px;
  background-color: #ffffff;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
}
.slideCompany_container:hover > .slideCompany_like {
  opacity: 1;
  visibility: visible;
}
