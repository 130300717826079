.cartModal_box-modal {
  transition: 0.8s all;
  visibility: hidden;
}
.cartModal_box-modal.visible {
  visibility: visible;
}
.cartModal_modal {
  position: fixed;
  width: 90%;
  margin: auto;
  top: 20px;
  left: 0;
  right: 0;
  max-width: 400px;
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.75);
  z-index: 11;
  animation: modalOut 0.8s forwards;
}
.cartModal_modal.visible {
  animation: modalIn 0.8s forwards;
}
.cartModal_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.cartModal_info img {
  width: 80px;
  height: 80px;
  margin: 5px;
}
.cartModal_name {
  width: calc(100% - 100px);
}
.cartModal_product {
  font-family: 'poppinsbold';
}
.cartModal_menu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.cartModal_menu .btn {
  margin: 10px 0 0 10px;
}
.cartModal_price-promo {
  text-decoration: line-through;
  color: #a6a6a6;
}

@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}
