.productItem_content {
  width: 100%;
  background: #ffffff;
  margin-bottom: 30px;
  padding: 15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.productItem_title {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'poppinsbold';
  line-height: 20px;
  margin-bottom: 10px;
}
.productItem_link {
  color: #000000;
  transition: all 0.2s;
}
.productItem_link:hover {
  text-decoration: none;
  color: #50318b;
}
.productItem_image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.productItem_menu {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.productItem_menu a,
.productItem_menu button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 30px;
  color: #000000;
  border: 0;
  background: transparent;
  transition: 0.2s all;
}
.productItem_menu a:hover,
.productItem_menu button:hover {
  color: #50318b;
  text-decoration: none;
}
