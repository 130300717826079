.userView_box-info {
  padding: 10px 0 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #50318b;
}
.userView_image-profile {
  width: 200px;
  height: 200px;
  border: 1px solid #dfe0e4;
  margin: 20px auto 10px;
  border-radius: 5px;
  padding: 10px;
}
.userView_image-profile img {
  width: 100%;
  height: 100%;
}
.userView_name {
  font-family: 'poppinsregular';
  font-size: 20px;
}
.userView_name-company {
  text-align: center;
}
.userView_activity {
  font-family: 'poppinsmedium';
  color: #50318b;
  margin-bottom: 5px;
}
.userView_row-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.userView_icon {
  width: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #db308b;
}
.userView_info {
  width: calc(100% - 30px);
}
.userView_box-info h5 {
  margin: 25px 0 10px;
  color: #db308b;
}
.userView_description {
  text-align: justify;
}
.userView_row-info a {
  color: #000000;
  transition: 0.2s all;
}
.userView_row-info a:hover {
  color: #50318b;
  text-decoration: none;
}
.userView_social {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.userView_social a {
  margin: 0 5px;
  font-size: 20px;
  transition: 0.2s all;
  color: #000000;
}
.userView_social a:hover {
  color: #db308b;
}
.userView_box-container {
  width: 100%;
  margin: 20px 0;
}
.userView_coverpage {
  width: 100%;
  height: auto;
}
.userView_profile-mobile,
.userView_social-mobile {
  display: none;
}
.userView_comments {
  width: 100%;
  height: 220px;
  overflow-x: auto;
  margin-bottom: 20px;
}
@media screen and (max-width: 1001px) {
  .userView_profile-desktop {
    display: none;
  }
  .userView_box-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
  }
  .userView_image-profile {
    width: 100px;
    height: 100px;
    margin: 0 10px 0 0;
    border-radius: 50%;
  }
  .userView_image-profile img {
    border-radius: 50%;
  }
  .userView_profile-mobile,
  .userView_social-mobile {
    display: block;
  }
  .userView_name-company {
    text-align: left;
    width: calc(100% - 110px);
  }
  .userView_social {
    border-top: 1px solid #dfe0e4;
    font-size: 14px;
  }
  .userView_social span {
    font-size: 14px;
  }
  .userView_menu-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .userView_menu-mobile button {
    width: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-bottom: 3px solid lightgray;
    background: none;
    transition: 0.2s all;
    color: #868e96;
  }
  .userView_menu-mobile button.visible {
    border-bottom: 3px solid #50318b;
    color: #000000;
  }
  .userView_info.no-visible,
  .userView_product-mobile.no-visible {
    display: none;
  }
}
