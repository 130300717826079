.form_content {
  font-family: 'poppinsregular';
}
.form_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.form_label {
  font-size: 16px;
  margin-top: 20px;
}
.form_label input {
  margin-right: 5px;
}
