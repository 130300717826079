.search_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background: rgba(0, 0, 0, 0.4);
  transition: 1s all;
  opacity: 0;
  visibility: hidden;
}
.search_container.active {
  opacity: 1;
  visibility: visible;
}
.search_box {
  width: 100%;
  max-width: 430px;
  background: #50318b;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  animation: modalOut 0.8s forwards;
}
.search_box.active {
  animation: modalIn 0.8s forwards;
}
.search_menu button {
  background: #ffffff;
  color: #50318b;
  transition: 0.2 all;
  margin: 0 5px 5px 0;
}
.search_menu button span {
  margin-right: 5px;
}
.search_menu button.active {
  background: #db308b;
  color: #ffffff;
}
.search_menu button:focus {
  outline: none;
}
.search_title {
  color: #f8a826;
  margin-bottom: 10px;
}
.search_form > select {
  border-radius: 5px 0 0 5px !important;
}
.search_form .input-group-text {
  color: #50318b !important;
  background: #ffffff;
}
.search_btn {
  background: #2aa9db !important;
  color: #ffffff !important;
}
.search_close {
  background: #db308b !important;
  color: #ffffff !important;
  margin-right: 5px;
}
.input-group-text span {
  font-size: 25px;
}
.search_input-group {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: #ffffff;
  margin-bottom: 10px;
  display: flex;
}
.search_input-group .search_input {
  width: calc(100% - 35px);
  height: 100%;
  border: 0;
  padding: 0 10px;
  background: none;
}
.search_input-group-append {
  width: 35px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #50318b;
}
/* .search_form > .input-group > select.form-control {
	border-radius: 0 !important;
} */
@keyframes modalIn {
  0% {
    transform: translateY(-3000px);
  }
  60% {
    transform: translateY(20px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes modalOut {
  0% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-3000px);
  }
}
@media screen and (max-width: 680px) {
  .search_container {
    align-items: flex-start;
    overflow: auto;
  }
  .search_box {
    max-width: 310px;
    margin: 20px 0 40px;
  }
}
