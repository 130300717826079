.shoppingCartView_container {
  margin-top: 20px;
}
.shoppingCartView_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'poppinssemibold';
  color: #db308b;
  margin-bottom: 20px;
  font-size: 22px;
}
.shoppingCartView_title-line {
  width: 110px;
  height: 7px;
  background: #db308b;
  margin: 0 auto;
}
