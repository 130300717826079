.adList_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--lightPurpleHover);
}
.adList_no-content {
  text-align: center;
  font-size: 20px;
  font-family: 'poppinssemibold';
}
.adList_card {
  margin-bottom: 25px;
}
.adList_card p {
  margin-bottom: 0;
}
.adList_card .card-title {
  margin-bottom: 5px;
}
.card-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.card-menu a,
.card-menu button {
  width: 50%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
}
.card-menu button {
  border: 0;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 5px 0 0 5px;
}
.card-menu a {
  background-color: var(--defaultPurple);
  color: #ffffff;
  border-radius: 0 5px 5px 0;
}
.card-menu a:hover {
  background-color: var(--purpleHover);
  color: #ffffff;
  text-decoration: none;
}
.card-menu button:hover {
  background-color: #c40000;
}
