.influencer-metric-table {
  width: 100%;
}

.influencer-metric-table thead {
  border-bottom: 2px solid var(--defaultPurple);
  color: var(--defaultPurple);
}
.influencer-metric-table thead th {
  min-width: 7rem;
  text-align: center;
  padding: 0.5rem 0;
  border-bottom: 2px solid var(--defaultPurple);
}
.action__button {
  text-align: right;
}
.action__button button {
  margin: 1rem 0;
  width: 10rem;
  height: 2rem;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: var(--defaultPurple);
  font-weight: bold;
}
.action__button button:hover {
  border: 1px solid var(--defaultPurple);
  transition: border 1s ease-in-out;
}
.metricTable__container {
  overflow: auto;
  margin-bottom: 2rem;
}
