.loading_container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 15;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading_container.loading_white {
  background: var(--modalWhite);
  color: var(--defaultPurple);
}
.loading_container.loading_black {
  background: var(--modal);
  color: var(--white);
}
