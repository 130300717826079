.settingsView_title {
  font-family: 'poppinsbold';
  color: #50318b;
  font-size: 18px;
  margin: 20px 10px 10px;
}
.settingsView_description {
  color: #50318b;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #50318b;
}
.settingsView_no-border {
  border-bottom: 0;
}
label {
  font-size: 14px;
  font-family: 'poppinsmedium';
  color: #50318b;
  margin-bottom: 5px !important;
}
.label-tc {
  font-size: initial;
  margin-bottom: initial !important;
}
.settingsView_box-basic {
  background: #ffffff;
  padding: 25px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.settingsView_box-basic input {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  margin-bottom: 10px;
}
.settingsView_save {
  color: #ffffff !important;
  background: #28cae4 !important;
}
.settingsView_save:hover {
  background: #17b6d0 !important;
}
.settingsView_row-email input {
  width: 230px;
}
.settingsView_row-email button {
  height: 45px;
  margin-left: 20px;
}
.settingsView_header-steps {
  padding-left: 10px;
}
.settingsView_steps {
  width: 70px;
  margin-right: 5px;
  border: 1px solid #f7f7f7;
  border-bottom: 0;
  background: #ffffff;
  position: relative;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}
.settingsView_steps button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #939ca1;
  color: #ffffff;
  border: 0;
}
.settingsView_steps button:focus {
  outline: none;
}
.settingsView_steps.active button {
  background: #50318b;
}
.settingsView_steps-icon {
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 5px;
  background: #db308b;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}
.settingsView_steps-container {
  padding: 15px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 30px;
}
.settingsView_page {
  display: none;
}
.settingsView_page.active {
  display: block;
}
.settingsView_page input,
.settingsView_page select,
.settingsView_page textarea {
  margin-bottom: 15px;
}
.settingsView_page textarea {
  height: 150px !important;
}
.settingsView_next {
  width: 120px;
  height: 45px;
  border-radius: 30px;
  border: 0;
  background: #db308b;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settingsView_next span {
  margin-right: 5px;
  font-size: 20px;
}
.settingsView_social span {
  color: #50318b;
  margin-bottom: 10px;
}
.settingsView_box-files {
  width: calc(100% / 3);
}
.settingsView_box-files:nth-child(2) {
  border-left: 1px solid #50318b;
  border-right: 1px solid #50318b;
}
.settingsView_container-files {
  margin-bottom: 20px;
}
.settingsView_files-title {
  height: 60px;
  font-family: 'poppinsmedium';
  border-top: 1px solid #50318b;
  border-bottom: 1px solid #50318b;
  text-align: center;
  color: #50318b;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}
.settingsView_files-content {
  padding: 10px 30px 20px;
}
.settingsView_files-square {
  height: 121px;
}
.settingsView_name-profile {
  height: 40px;
  display: flex;
  align-items: center;
}
.settingsView_name-profile .form-control {
  margin: 0;
}
.settingsView_message {
  height: 25px;
  text-align: right;
  font-size: 12px;
}
.settingsView_error {
  color: red;
}
.settingsView_success {
  color: #50318b;
}
.settingsView_company {
  border: 1px solid #939ca1;
  margin: 10px 0;
  border-radius: 5px;
  padding: 15px 15px 0;
  display: flex;
  justify-content: space-between;
}
.settingsView_companies-button {
  padding: 10px 20px;
  background-color: #db308b;
  border: 0;
  color: #ffffff;
  border-radius: 5px;
}
.settingsView_companies-button i {
  margin-right: 5px;
}
.settingsView_company-form-content {
  width: calc(100% - 50px);
}
.settingsView_company-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.settingsView_company-remove {
  width: 40px;
  border: 0;
  padding: 0;
  background-color: red;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transition: 0.2s all;
  border-radius: 5px;
}
.settingsView_company-remove:hover {
  background-color: darkred;
}
.settingsView_company-input {
  width: calc(100% / 3 - 10px);
}
.settingsView_company-form-first .settingsView_company-input {
  width: calc(100% / 2 - 10px);
}
.settingsView_company-input label {
  margin-bottom: 0 !important;
}
.settingsView_company-input label::after {
  content: ':';
}
.settingsView_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.settingsView_col {
  width: 50%;
}
.settingsView_col:nth-child(1) {
  border-right: 1px solid var(--defaultPurple);
}
.settingsView_checkbox {
  font-size: 16px;
}
.settingsView_checkbox {
  margin-bottom: 20px;
}
.settingsView_checkbox label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
}
.settingsView_checkbox input {
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 6px;
}
@media screen and (max-width: 768px) {
  .settingsView_company-input,
  .settingsView_company-form-first .settingsView_company-input,
  .settingsView_col {
    width: 100%;
  }
  .settingsView_col:nth-child(1) {
    border-right: 0;
  }
}
