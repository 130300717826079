.collapseComponent {
  margin-bottom: 10px;
}
.collapseComponent__container {
  padding: 10px 0;
}
.collapser {
  padding: 0;
  width: 100%;
  text-align: left;
  color: var(--defaultPurple);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  /* background-color: var(--defaultPurple); */
}
.head__collapser {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.collapser__title {
  font-size: 0.98rem;
}
.superTitle {
  color: var(--defaultPink);
  font-size: 1rem;
}
.collapseComponent__container .keywords {
  margin-bottom: 0;
}
.collapseComponent__container .filter__btn {
  margin-top: 0;
}
