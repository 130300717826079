.box-loading {
  width: 100%;
  height: 100%;
  background: #50318B;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-loading img {
  width: 90%;
  max-width: 320px;
}
.box-loading h6 {
  text-align: center;
  color: #ffffff;
  margin: 10px 0;
}